import React from 'react'
import SectionBreaker from '../components/SectionBreaker'
import SecondContainer from '../components/SecondContainer'
import SRF from '../assets/SRF'
import BILANZ from '../assets/BILANZ'
import SWISSSTARTUP from '../assets/SWISSSTARTUP'
import NZZ from '../assets/NZZ'
import HANDELSZEITUNG from '../assets/HANDELSZEITUNG'
import BLICK from '../assets/BLICK'
import SEFGROWTH from '../assets/SEFGROWTH'

const KnownSection = () => {
    return (
        <SectionBreaker>
            <SecondContainer>
                <div className='flex flex-col gap-[15px] lg:gap-[5px]'>
                    <div className='hidden lg:grid lg:grid-cols-7 2xl:grid-cols-7 gap-[20px]'>
                        <SRF width={'lg:25px'} height={'lg:25px'}/>
                        <BILANZ  width={'lg:25px'}/>
                        <SWISSSTARTUP  width={'lg:25px'}/>
                        <NZZ  width={'lg:25px'}/>
                        <HANDELSZEITUNG  width={'lg:25px'}/>
                        <BLICK  width={'lg:25px'}/>
                        <SEFGROWTH  width={'lg:25px'}/>
                    </div>
                    <div className='hidden lg:flex text-[16px] font-normal font-inter'>
                        Bekannt aus
                    </div>
                    <div className='grid grid-cols-2 gap-[10px] lg:hidden'>
                        <SRF width={'161.53px'} height={'87.93px'}/>
                        <BILANZ width={'161.53px'} height={'87.93px'}/>
                        <SWISSSTARTUP width={'161.53px'} height={'87.93px'}/>
                        <NZZ width={'161.53px'} height={'87.93px'}/>
                        <HANDELSZEITUNG width={'161.53px'} height={'87.93px'}/>
                        <BLICK width={'161.53px'} height={'87.93px'}/>
                        {/* <SEFGROWTH width={'161.53px'} height={'87.93px'}/> */}
                    </div>
                    <div className='text-[16px] font-normal font-inter lg:hidden'>
                        Bekannt aus
                    </div>
                </div>

            </SecondContainer>
        </SectionBreaker>
    )
}

export default KnownSection
