import React from 'react'
import SectionBreaker from '../components/SectionBreaker'
import SecondContainer from '../components/SecondContainer'
import Browse from '../components/Browse'
import HomeCards from '../components/HomeCards'
import Home1 from '../assets/Home1.png'
import Home2 from '../assets/Home2.png'
import Home3 from '../assets/Home3.png'
import Home4 from '../assets/Home4.png'
import Tree from '../assets/Tree'
import Charger from '../assets/Charger'
import { useTranslation } from 'react-i18next'


const HomesSection = () => {

    const { t } = useTranslation();
    return (
        <SectionBreaker>
            <SecondContainer>
                <div className='flex flex-col gap-[30px] lg:gap-[60px]'>
                    <div className='text-[#30313A] text-[24px]  lg:text-[38.81px] font-semibold'>
                        {t('homes-title')}
                    </div>
                    <div className='flex flex-col gap-[30px]'>
                        <Browse svg={<Tree />} title={`${t('nature-title')}`} text={`${t('nature-desc')}`} button={'Browse now'} />
                        <div className='grid gap-y-[50px] lg:grid-cols-2 xl:grid-cols-4 lg:gap-y-[60px] lg:gap-[20px] lg:gap-y-[0px] xl:gap-[10px]'>
                            <HomeCards
                                img={Home1}
                                price={'$389,900'}
                                address={'957 S 120 W, American Fork, UT 84003'}
                                beds={'2 beds'}
                                baths={'3 baths'}
                                sqft={'925 sqft'}
                                Importants1={'bike friendly'}
                                Importants2={'Nearby Parks Playgrounds'}
                                Importants3={'3 more'}
                                day={'1d ago'} time={'Sun, 11:00-1:00 PM'} />
                            <HomeCards
                                img={Home2}
                                price={'$735,000'}
                                address={'957 S 120 W, American Fork, UT 84003'}
                                beds={'4 beds'}
                                baths={'3 baths'}
                                sqft={'2863 sqft'}
                                Importants1={'Hardwood Floors'}
                                Importants2={'Big Yard'}
                                Importants3={'4 more'}
                                day={'1d ago'} time={'Sun, 11:00-1:00 PM'} />
                            <HomeCards
                                img={Home3}
                                price={'$1,330,000'}
                                address={'957 S 120 W, American Fork, UT 84003'}
                                beds={'5 beds'}
                                baths={'3 baths'}
                                sqft={'2321 sqft'}
                                Importants1={'Hardwood Floors'}
                                Importants2={'Big Yard'}
                                Importants3={'5 more'}
                                day={'1d ago'} time={'Sun, 11:00-1:00 PM'} />
                            <HomeCards
                                img={Home4}
                                price={'$4,400,000'}
                                address={'957 S 120 W, American Fork, UT 84003'}
                                beds={'4 beds'}
                                baths={'3.75 baths'}
                                sqft={'6820 sqft'}
                                Importants1={'Detached Structure'}
                                Importants2={'Nearby Parks Playgrounds'}
                                Importants3={'6 more'}
                                day={'1d ago'} time={'Sun, 11:00-1:00 PM'} />
                        </div>
                    </div>

                    <div className='hidden lg:flex flex-col gap-[30px]'>
                        <Browse svg={<Charger />} title={`${t('ev-title')}`} text={`${t('ev-natur')}`} button={'Browse now'} />
                        <div className='grid  lg:grid-cols-2 xl:grid-cols-4 lg:gap-y-[60px] lg:gap-[20px] lg:gap-y-[0px] xl:gap-[10px]'>
                            <HomeCards
                                img={Home1}
                                price={'$389,900'}
                                address={'957 S 120 W, American Fork, UT 84003'}
                                beds={'2 beds'}
                                baths={'3 baths'}
                                sqft={'925 sqft'}
                                Importants1={'bike friendly'}
                                Importants2={'Nearby Parks Playgrounds'}
                                Importants3={'3 more'}
                                day={'1d ago'} time={'Sun, 11:00-1:00 PM'} />
                            <HomeCards
                                img={Home2}
                                price={'$735,000'}
                                address={'957 S 120 W, American Fork, UT 84003'}
                                beds={'4 beds'}
                                baths={'3 baths'}
                                sqft={'2863 sqft'}
                                Importants1={'Hardwood Floors'}
                                Importants2={'Big Yard'}
                                Importants3={'4 more'}
                                day={'1d ago'} time={'Sun, 11:00-1:00 PM'} />
                            <HomeCards
                                img={Home3}
                                price={'$1,330,000'}
                                address={'957 S 120 W, American Fork, UT 84003'}
                                beds={'5 beds'}
                                baths={'3 baths'}
                                sqft={'2321 sqft'}
                                Importants1={'Hardwood Floors'}
                                Importants2={'Big Yard'}
                                Importants3={'5 more'}
                                day={'1d ago'} time={'Sun, 11:00-1:00 PM'} />
                            <HomeCards
                                img={Home4}
                                price={'$4,400,000'}
                                address={'957 S 120 W, American Fork, UT 84003'}
                                beds={'4 beds'}
                                baths={'3.75 baths'}
                                sqft={'6820 sqft'}
                                Importants1={'Detached Structure'}
                                Importants2={'Nearby Parks Playgrounds'}
                                Importants3={'6 more'}
                                day={'1d ago'} time={'Sun, 11:00-1:00 PM'} />
                        </div>
                    </div>

                </div>
            </SecondContainer>
        </SectionBreaker>

    )
}

export default HomesSection
