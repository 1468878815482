import React from 'react'
import SecondContainer from '../components/SecondContainer'
// import SectionBreaker from '../components/SectionBreaker'
import Offerscard from '../components/Offerscard'
import House from '../assets/House'
import Payment from '../assets/Payment'
import Innovativedesigns from '../assets/Innovativedesigns'
import Agents from '../assets/Agents'
import { useTranslation } from 'react-i18next'

const OffersSection = () => {
    const { t } = useTranslation()
    return (
        <div className='pt-[60px] lg:pt-[120px]'>
            <SecondContainer>
                <div className='flex flex-col gap-[30px]'>
                    <div className='text-[#1D2123] text-[16px] w-full max-w-[300px] lg:max-w-none lg:text-[24.3px] font-medium'>
                        At pellentesque imperdiet ultricies pretium.
                    </div>
                    <div className='grid grid-cols-2 gap-[15px] lg:grid-cols-4 lg:gap-[25px]'>
                        <Offerscard svg={<House />} title={`${t('quality')}`} text={`${t('description-quality')}`} />
                        <Offerscard svg={<Payment />} title={`${t('payment')}`} text={`${t('description-payment')}`} />
                        <Offerscard svg={<Innovativedesigns />} title={`${t('innovative')}`} text={`${t('description-innovative')}`} />
                        <Offerscard svg={<Agents />} title={`${t('agents')}`} text={`${t('description-agents')}`} />
                    </div>
                </div>
            </SecondContainer>
        </div>
    )
}

export default OffersSection
