import React from 'react';

const Importants = ({ text, timer, day }) => {
    return (
        <div className={`rounded-[5.4px] ${timer ? 'text-black bg-[#FFF]' : day ? 'text-white bg-[#1E262F]' : 'text-[#6E6F75] bg-[#F9F9FA]'} font-medium text-[14px] lg:text-[9px]  2xl:text-[12px] px-[10px] py-[5px]`}>
            {text || timer || day}
        </div>
    );
}

export default Importants;
