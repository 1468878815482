import React from 'react'

const House = () => {
    return (
        <svg width="120" height="121" viewBox="0 0 120 121" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 50.3086L35 20.3086M35 20.3086L62.0711 47.3797C63.5162 48.8248 64.2388 49.5474 65.1577 49.928C66.0765 50.3086 67.0984 50.3086 69.1421 50.3086H110L90.9969 27.5049C88.048 23.9662 86.5735 22.1968 84.5578 21.2527C82.5421 20.3086 80.2389 20.3086 75.6325 20.3086H35Z" stroke="#141B34" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M55 40.3086V100.309H15V44.5943" stroke="#141B34" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M55 100.309H105V45.3086" stroke="#141B34" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M20 37.8086L20 20.3086" stroke="#141B34" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M35.04 60.3086L34.9951 60.3086" stroke="#141B34" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M35 100.309L35 80.3086" stroke="#141B34" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M75 70.3086L85 70.3086" stroke="#141B34" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default House
