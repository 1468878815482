import React from 'react'

const IMMOVERBAND = ({ color }) => {
    return (
        <svg width="131" height="16" viewBox="0 0 131 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.07528 0.830078V15.3755H0V0.830078H3.07528Z" fill={color || "white"} />
            <path d="M5.34865 15.3755V4.46644H8.23217V6.39116H8.36001C8.58729 5.75195 8.96607 5.24769 9.49638 4.87837C10.0267 4.50906 10.6612 4.3244 11.3998 4.3244C12.1479 4.3244 12.7847 4.51142 13.3103 4.88548C13.8359 5.25479 14.1862 5.75669 14.3614 6.39116H14.4751C14.6976 5.76616 15.1001 5.26663 15.6825 4.89258C16.2696 4.51379 16.9632 4.3244 17.7634 4.3244C18.7814 4.3244 19.6076 4.64873 20.2421 5.29741C20.8813 5.94135 21.2009 6.85517 21.2009 8.03889V15.3755H18.1825V8.63548C18.1825 8.02942 18.0215 7.57487 17.6995 7.27184C17.3775 6.96881 16.9751 6.81729 16.4921 6.81729C15.9429 6.81729 15.5144 6.99248 15.2066 7.34286C14.8988 7.68851 14.745 8.14542 14.745 8.7136V15.3755H11.8117V8.57156C11.8117 8.03652 11.6578 7.61038 11.3501 7.29315C11.047 6.97591 10.6469 6.81729 10.1498 6.81729C9.81361 6.81729 9.51058 6.90252 9.2407 7.07298C8.97554 7.2387 8.76484 7.47307 8.60859 7.7761C8.45234 8.0744 8.37422 8.42478 8.37422 8.82724V15.3755H5.34865Z" fill={color || "white"} />
            <path d="M23.3908 15.3755V4.46644H26.2744V6.39116H26.4022C26.6295 5.75195 27.0083 5.24769 27.5386 4.87837C28.0689 4.50906 28.7033 4.3244 29.442 4.3244C30.1901 4.3244 30.8269 4.51142 31.3525 4.88548C31.8781 5.25479 32.2284 5.75669 32.4036 6.39116H32.5173C32.7398 5.76616 33.1423 5.26663 33.7246 4.89258C34.3118 4.51379 35.0054 4.3244 35.8056 4.3244C36.8236 4.3244 37.6498 4.64873 38.2843 5.29741C38.9235 5.94135 39.2431 6.85517 39.2431 8.03889V15.3755H36.2246V8.63548C36.2246 8.02942 36.0637 7.57487 35.7417 7.27184C35.4197 6.96881 35.0173 6.81729 34.5343 6.81729C33.9851 6.81729 33.5566 6.99248 33.2488 7.34286C32.941 7.68851 32.7871 8.14542 32.7871 8.7136V15.3755H29.8539V8.57156C29.8539 8.03652 29.7 7.61038 29.3923 7.29315C29.0892 6.97591 28.6891 6.81729 28.192 6.81729C27.8558 6.81729 27.5528 6.90252 27.2829 7.07298C27.0177 7.2387 26.807 7.47307 26.6508 7.7761C26.4945 8.0744 26.4164 8.42478 26.4164 8.82724V15.3755H23.3908Z" fill={color || "white"} />
            <path d="M46.362 15.5886C45.2588 15.5886 44.3047 15.3542 43.4998 14.8855C42.6996 14.412 42.0817 13.7538 41.6461 12.911C41.2105 12.0635 40.9927 11.081 40.9927 9.9636C40.9927 8.83671 41.2105 7.85186 41.6461 7.00906C42.0817 6.16152 42.6996 5.50337 43.4998 5.03462C44.3047 4.56114 45.2588 4.3244 46.362 4.3244C47.4652 4.3244 48.4169 4.56114 49.2171 5.03462C50.022 5.50337 50.6423 6.16152 51.0779 7.00906C51.5135 7.85186 51.7313 8.83671 51.7313 9.9636C51.7313 11.081 51.5135 12.0635 51.0779 12.911C50.6423 13.7538 50.022 14.412 49.2171 14.8855C48.4169 15.3542 47.4652 15.5886 46.362 15.5886ZM46.3762 13.2449C46.8781 13.2449 47.2971 13.1028 47.6333 12.8187C47.9695 12.5299 48.2228 12.1369 48.3933 11.6397C48.5684 11.1426 48.656 10.5768 48.656 9.94229C48.656 9.30782 48.5684 8.74201 48.3933 8.24485C48.2228 7.74769 47.9695 7.3547 47.6333 7.06587C47.2971 6.77705 46.8781 6.63264 46.3762 6.63264C45.8696 6.63264 45.4434 6.77705 45.0978 7.06587C44.7569 7.3547 44.4988 7.74769 44.3237 8.24485C44.1532 8.74201 44.068 9.30782 44.068 9.94229C44.068 10.5768 44.1532 11.1426 44.3237 11.6397C44.4988 12.1369 44.7569 12.5299 45.0978 12.8187C45.4434 13.1028 45.8696 13.2449 46.3762 13.2449Z" fill={color || "white"} />
            <path d="M63.3175 4.46644L59.5036 15.3755H56.0945L52.2806 4.46644H55.4766L57.7423 12.2718H57.8559L60.1144 4.46644H63.3175Z" fill={color || "white"} />
            <path d="M69.2859 15.5886C68.1637 15.5886 67.1978 15.3613 66.3881 14.9068C65.5832 14.4475 64.963 13.7988 64.5273 12.9608C64.0917 12.118 63.8739 11.1213 63.8739 9.9707C63.8739 8.84854 64.0917 7.8637 64.5273 7.01616C64.963 6.16862 65.5761 5.50811 66.3668 5.03462C67.1623 4.56114 68.0951 4.3244 69.1651 4.3244C69.8848 4.3244 70.5548 4.4404 71.1751 4.67241C71.8001 4.89968 72.3446 5.24296 72.8086 5.70224C73.2773 6.16152 73.6419 6.73917 73.9023 7.43519C74.1628 8.12648 74.293 8.93614 74.293 9.86417V10.6951H65.0813V8.82014H71.445C71.445 8.38453 71.3503 7.99864 71.1609 7.66247C70.9715 7.32629 70.7087 7.06351 70.3725 6.87411C70.0411 6.67998 69.6552 6.58292 69.2148 6.58292C68.7556 6.58292 68.3484 6.68945 67.9933 6.90252C67.6429 7.11085 67.3682 7.39258 67.1694 7.74769C66.9705 8.09807 66.8687 8.4887 66.864 8.91957V10.7022C66.864 11.242 66.9634 11.7084 67.1623 12.1014C67.3659 12.4944 67.6523 12.7974 68.0217 13.0105C68.391 13.2235 68.8289 13.3301 69.3356 13.3301C69.6718 13.3301 69.9795 13.2827 70.2589 13.188C70.5382 13.0933 70.7773 12.9513 70.9762 12.7619C71.1751 12.5725 71.3266 12.3405 71.4308 12.0659L74.229 12.2505C74.087 12.9229 73.7958 13.51 73.3555 14.0119C72.9199 14.5091 72.3564 14.8973 71.6651 15.1767C70.9786 15.4513 70.1855 15.5886 69.2859 15.5886Z" fill={color || "white"} />
            <path d="M76.0674 15.3755V4.46644H79.0006V6.36985H79.1143C79.3131 5.69277 79.6469 5.1814 80.1157 4.83576C80.5844 4.48538 81.1242 4.31019 81.735 4.31019C81.8865 4.31019 82.0499 4.31966 82.2251 4.3386C82.4003 4.35754 82.5541 4.38358 82.6867 4.41673V7.10139C82.5447 7.05877 82.3482 7.02089 82.0972 6.98775C81.8463 6.95461 81.6166 6.93803 81.4083 6.93803C80.9632 6.93803 80.5655 7.0351 80.2151 7.22923C79.8695 7.41862 79.5949 7.68377 79.3913 8.02468C79.1924 8.36559 79.093 8.75858 79.093 9.20366V15.3755H76.0674Z" fill={color || "white"}/>
            <path d="M84.1078 15.3755V0.830078H87.1334V6.29883H87.2257C87.3583 6.00527 87.55 5.70697 87.801 5.40394C88.0567 5.09618 88.3881 4.8405 88.7953 4.6369C89.2072 4.42856 89.7186 4.3244 90.3294 4.3244C91.1249 4.3244 91.8588 4.53273 92.5311 4.9494C93.2035 5.36133 93.7409 5.98396 94.1433 6.81729C94.5458 7.64589 94.747 8.68519 94.747 9.93519C94.747 11.152 94.5505 12.1795 94.1575 13.0176C93.7693 13.8509 93.239 14.483 92.5666 14.9139C91.899 15.34 91.1509 15.5531 90.3223 15.5531C89.7352 15.5531 89.2357 15.456 88.8237 15.2619C88.4165 15.0678 88.0827 14.8239 87.8223 14.5304C87.5619 14.2321 87.363 13.9314 87.2257 13.6284H87.0908V15.3755H84.1078ZM87.0695 9.92099C87.0695 10.5697 87.1594 11.1355 87.3393 11.6184C87.5193 12.1014 87.7797 12.4778 88.1206 12.7477C88.4615 13.0128 88.8758 13.1454 89.3635 13.1454C89.8559 13.1454 90.2726 13.0105 90.6135 12.7406C90.9544 12.466 91.2125 12.0872 91.3876 11.6042C91.5676 11.1165 91.6575 10.5555 91.6575 9.92099C91.6575 9.29125 91.5699 8.73728 91.3947 8.25906C91.2196 7.78084 90.9615 7.40678 90.6206 7.1369C90.2797 6.86701 89.8607 6.73207 89.3635 6.73207C88.8711 6.73207 88.4544 6.86228 88.1135 7.12269C87.7773 7.38311 87.5193 7.75243 87.3393 8.23065C87.1594 8.70887 87.0695 9.27231 87.0695 9.92099Z" fill={color || "white"} />
            <path d="M99.6766 15.5815C98.9806 15.5815 98.3604 15.4608 97.8158 15.2193C97.2713 14.9731 96.8405 14.6109 96.5232 14.1326C96.2107 13.6497 96.0545 13.0484 96.0545 12.3287C96.0545 11.7226 96.1657 11.2136 96.3883 10.8017C96.6108 10.3897 96.9138 10.0583 97.2974 9.80735C97.6809 9.5564 98.1165 9.36701 98.6042 9.23917C99.0966 9.11133 99.6127 9.02137 100.152 8.96928C100.787 8.903 101.298 8.84144 101.687 8.78462C102.075 8.72307 102.357 8.63311 102.532 8.51474C102.707 8.39637 102.795 8.22118 102.795 7.98917V7.94656C102.795 7.49675 102.652 7.14873 102.368 6.90252C102.089 6.65631 101.691 6.5332 101.175 6.5332C100.631 6.5332 100.197 6.65394 99.8755 6.89542C99.5535 7.13216 99.3405 7.43046 99.2363 7.79031L96.438 7.56303C96.58 6.90015 96.8594 6.32724 97.2761 5.84428C97.6927 5.35659 98.2301 4.98254 98.8883 4.72212C99.5512 4.45697 100.318 4.3244 101.189 4.3244C101.795 4.3244 102.375 4.39542 102.929 4.53746C103.488 4.67951 103.983 4.89968 104.414 5.19798C104.849 5.49627 105.193 5.87979 105.444 6.34854C105.695 6.81256 105.82 7.3689 105.82 8.01758V15.3755H102.951V13.8627H102.866C102.69 14.2037 102.456 14.5043 102.162 14.7647C101.869 15.0204 101.516 15.2217 101.104 15.3684C100.692 15.5105 100.216 15.5815 99.6766 15.5815ZM100.543 13.4934C100.988 13.4934 101.381 13.4058 101.722 13.2306C102.063 13.0507 102.331 12.8092 102.525 12.5062C102.719 12.2032 102.816 11.8599 102.816 11.4764V10.3187C102.721 10.3803 102.591 10.4371 102.425 10.4892C102.264 10.5365 102.082 10.5815 101.878 10.6241C101.675 10.662 101.471 10.6975 101.268 10.7306C101.064 10.7591 100.879 10.7851 100.714 10.8088C100.358 10.8609 100.048 10.9437 99.7832 11.0574C99.518 11.171 99.312 11.3249 99.1653 11.519C99.0185 11.7084 98.9451 11.9451 98.9451 12.2292C98.9451 12.6412 99.0943 12.956 99.3925 13.1738C99.6956 13.3869 100.079 13.4934 100.543 13.4934Z" fill={color || "white"} />
            <path d="M110.993 9.06872V15.3755H107.967V4.46644H110.851V6.39116H110.979C111.22 5.75669 111.625 5.25479 112.193 4.88548C112.761 4.51142 113.45 4.3244 114.26 4.3244C115.018 4.3244 115.678 4.49012 116.242 4.82156C116.805 5.153 117.243 5.62648 117.555 6.24201C117.868 6.85281 118.024 7.58197 118.024 8.42951V15.3755H114.999V8.96928C115.003 8.30167 114.833 7.78084 114.487 7.40678C114.142 7.028 113.666 6.8386 113.06 6.8386C112.653 6.8386 112.293 6.9262 111.98 7.10139C111.672 7.27657 111.431 7.53226 111.256 7.86843C111.085 8.19987 110.998 8.59997 110.993 9.06872Z" fill={color || "white"} />
            <path d="M124.229 15.5531C123.4 15.5531 122.65 15.34 121.977 14.9139C121.31 14.483 120.779 13.8509 120.386 13.0176C119.998 12.1795 119.804 11.152 119.804 9.93519C119.804 8.68519 120.005 7.64589 120.408 6.81729C120.81 5.98396 121.345 5.36133 122.013 4.9494C122.685 4.53273 123.421 4.3244 124.222 4.3244C124.832 4.3244 125.341 4.42856 125.749 4.6369C126.161 4.8405 126.492 5.09618 126.743 5.40394C126.999 5.70697 127.193 6.00527 127.325 6.29883H127.418V0.830078H130.436V15.3755H127.453V13.6284H127.325C127.183 13.9314 126.982 14.2321 126.722 14.5304C126.466 14.8239 126.132 15.0678 125.72 15.2619C125.313 15.456 124.816 15.5531 124.229 15.5531ZM125.188 13.1454C125.675 13.1454 126.087 13.0128 126.423 12.7477C126.764 12.4778 127.025 12.1014 127.205 11.6184C127.389 11.1355 127.482 10.5697 127.482 9.92099C127.482 9.27231 127.392 8.70887 127.212 8.23065C127.032 7.75243 126.771 7.38311 126.43 7.12269C126.089 6.86228 125.675 6.73207 125.188 6.73207C124.69 6.73207 124.271 6.86701 123.93 7.1369C123.589 7.40678 123.331 7.78084 123.156 8.25906C122.981 8.73728 122.893 9.29125 122.893 9.92099C122.893 10.5555 122.981 11.1165 123.156 11.6042C123.336 12.0872 123.594 12.466 123.93 12.7406C124.271 13.0105 124.69 13.1454 125.188 13.1454Z" fill={color || "white"}/>
        </svg>
    )
}

export default IMMOVERBAND
