import React from 'react'

const Browse = ({ svg, title, text, button }) => {
    return (
        <div className='flex flex-col  gap-[25px] lg:gap-[0px] flex-col-reverse lg:flex-row justify-between items-start lg:items-center'>
            <div className='flex flex-col gap-[10px] lg:gap-[0px]'>
                <div className='flex flex-row items-center gap-[7px] font-semibold text-[18.45px] lg:text-[26px] text-[#30313A]'>
                    {svg} {title}
                </div>
                <div className='text-[#30313A] text-[14px] lg:text-[16px] font-normal'>
                    {text}
                </div>
            </div>
            <button className='w-full lg:max-w-[150px] py-[13px] px-[20px] border border-[0.9px solid #98989D] flex justify-center rounded-[5.4px] items-center text-[#30313A] text-[14.29px] font-normal'>
                {button}
            </button>
        </div>
    )
}

export default Browse
