import React from 'react'

const HANDELSZEITUNG = ({ width, height }) => {
    return (
        <svg width={width || "140"} height={height || "76"} viewBox="0 0 140 76" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1248_10072)">
                <g clip-path="url(#clip1_1248_10072)">
                    <g clip-path="url(#clip2_1248_10072)">
                        <path d="M3.13235 0.503906H136.222C137.455 0.503906 138.523 1.48976 138.523 2.80423V73.0462C138.523 74.2785 137.537 75.3465 136.222 75.3465H3.13235C1.90004 75.3465 0.832031 74.3607 0.832031 73.0462V2.80423C0.832031 1.48976 1.81788 0.503906 3.13235 0.503906Z" fill="white" />
                        <path d="M3.13235 0.503906H136.222C137.455 0.503906 138.523 1.48976 138.523 2.80423V73.0462C138.523 74.2785 137.537 75.3465 136.222 75.3465H3.13235C1.90004 75.3465 0.832031 74.3607 0.832031 73.0462V2.80423C0.832031 1.48976 1.81788 0.503906 3.13235 0.503906Z" stroke="#E0E0E0" stroke-width="0.821544" />
                        <path d="M123.079 42.0666C122.997 42.0666 122.833 41.9845 122.751 41.9845C120.861 41.4915 119.546 39.6841 119.629 37.7946C119.711 35.7407 121.19 34.0155 123.161 33.769C124.722 33.5226 125.955 34.0155 127.023 35.1657C127.023 35.2478 127.105 35.2478 127.187 35.4121C126.776 35.6586 126.447 35.9872 126.037 36.2337C126.037 36.1515 125.955 36.0694 125.872 36.0694C125.051 35.2478 124.147 34.9192 122.997 35.2478C121.847 35.5764 121.19 36.3158 120.943 37.3838C120.532 39.1091 122.011 40.8343 123.819 40.8343C124.887 40.8343 125.708 40.2592 126.119 39.3555C126.201 39.1912 126.201 39.0269 126.283 38.9448C125.544 38.9448 124.804 38.9448 124.147 38.9448C124.147 38.534 124.147 38.0411 124.147 37.6303C124.229 37.6303 124.311 37.6303 124.311 37.6303C125.379 37.6303 126.447 37.6303 127.515 37.6303C127.598 37.6303 127.68 37.6303 127.762 37.6303C127.762 37.8768 127.762 38.1232 127.762 38.3697C127.762 38.534 127.68 38.6983 127.68 38.9448C127.351 40.5878 126.447 41.6558 124.804 42.1488C124.722 42.1488 124.64 42.1488 124.64 42.2309C124.229 42.0666 123.654 42.0666 123.079 42.0666Z" fill="#1E262F" />
                        <path d="M66.5568 42.0674C66.3103 42.0674 66.0639 41.9852 65.8174 41.9031C65.2423 41.7388 64.5851 41.5744 64.0922 41.1637C64.3386 40.8351 64.5851 40.4243 64.7494 40.0957C65.3245 40.26 65.8996 40.5064 66.4747 40.6707C67.1319 40.8351 67.7891 40.8351 68.4464 40.6707C68.6928 40.5886 68.8571 40.5064 69.0214 40.3421C69.4322 40.0135 69.4322 39.4384 69.0214 39.1098C68.6928 38.8633 68.2821 38.7812 67.8713 38.6169C67.1319 38.2883 66.3925 38.124 65.6531 37.7953C65.3245 37.631 65.078 37.4667 64.7494 37.2203C63.6814 36.2344 63.9279 34.5913 65.2423 34.0162C65.8996 33.6876 66.5568 33.6055 67.2962 33.6055C68.2821 33.6055 69.5965 33.9341 70.5002 34.5092C70.2538 34.8378 70.0894 35.1664 69.843 35.495C69.5144 35.3307 69.2679 35.2486 68.9393 35.0842C68.1177 34.7556 67.214 34.6735 66.3925 34.9199C66.2282 34.9199 66.0639 35.0021 65.9817 35.0842C65.4888 35.4129 65.4066 35.9879 65.8996 36.3166C66.2282 36.563 66.639 36.7273 66.9676 36.8916C67.6248 37.1381 68.2821 37.3024 68.9393 37.5489C69.2679 37.7132 69.5965 37.8775 69.9251 38.0418C71.0753 38.8633 71.0753 40.5064 69.9251 41.328C69.4322 41.6566 68.8571 41.8209 68.2821 41.9031C68.1999 41.9031 68.1177 41.9031 68.1177 41.9031C67.5427 42.0674 67.0497 42.0674 66.5568 42.0674Z" fill="#1E262F" />
                        <path d="M103.691 42.067C103.444 41.9848 103.28 41.9027 103.034 41.8205C101.801 41.3276 101.144 40.3418 100.898 39.1094C100.815 38.7808 100.815 38.3701 100.815 38.0414C100.815 36.727 100.815 35.4125 100.815 34.0159C100.815 33.9337 100.815 33.8516 100.815 33.8516C101.308 33.8516 101.719 33.8516 102.212 33.8516C102.212 33.9337 102.212 34.0159 102.212 34.1802C102.212 35.5768 102.212 36.8913 102.212 38.2879C102.212 38.7808 102.294 39.1916 102.541 39.6845C102.951 40.5882 103.937 40.999 104.923 40.8347C106.155 40.6704 106.977 39.8488 106.977 38.5344C106.977 37.0556 106.977 35.659 106.977 34.1802C106.977 34.098 106.977 34.0159 106.977 33.8516C107.388 33.8516 107.881 33.8516 108.291 33.8516C108.291 33.8516 108.291 33.9337 108.291 34.0159C108.291 35.4947 108.291 36.8913 108.291 38.3701C108.291 38.9451 108.209 39.6024 107.881 40.0953C107.388 41.0811 106.566 41.6562 105.498 41.9848C105.498 41.9848 105.416 41.9848 105.334 42.067C104.841 42.067 104.266 42.067 103.691 42.067Z" fill="#1E262F" />
                        <path d="M117.739 41.9026C117.41 41.9026 117.163 41.9026 116.835 41.9026C116.753 41.9026 116.67 41.8204 116.588 41.7383C115.274 40.1773 113.877 38.6164 112.563 37.0555C112.316 36.809 112.152 36.5625 111.906 36.3161C111.906 36.3161 111.906 36.3161 111.823 36.3161C111.823 36.3982 111.823 36.4804 111.823 36.5625C111.823 38.2878 111.823 39.9309 111.823 41.6561C111.823 41.9026 111.741 41.9847 111.495 41.9847C111.166 41.9847 110.755 41.9847 110.427 41.9847C110.427 39.2736 110.427 36.6447 110.427 33.9336C110.755 33.9336 111.002 33.9336 111.33 33.9336C111.413 33.9336 111.495 34.0157 111.577 34.0979C113.056 35.8231 114.617 37.6305 116.178 39.3558C116.178 39.4379 116.26 39.4379 116.342 39.6022C116.342 37.7127 116.342 35.8231 116.342 33.9336C116.835 33.9336 117.246 33.9336 117.739 33.9336C117.739 36.4804 117.739 39.1915 117.739 41.9026Z" fill="#1E262F" />
                        <path d="M35.913 39.4381C35.913 37.5485 35.913 35.7411 35.913 33.8516C36.406 33.8516 36.8167 33.8516 37.3097 33.8516C37.3097 36.4805 37.3097 39.1094 37.3097 41.8205C36.981 41.8205 36.6524 41.8205 36.406 41.8205C36.3238 41.8205 36.2417 41.7384 36.2417 41.6562C34.7629 39.931 33.2019 38.1236 31.7232 36.3983C31.641 36.3162 31.641 36.234 31.4767 36.234C31.4767 38.1236 31.4767 39.931 31.4767 41.8205C31.0659 41.8205 30.573 41.8205 30.1622 41.8205L30.0801 41.7384C30.0801 41.6562 30.0801 41.5741 30.0801 41.5741C30.0801 39.1094 30.0801 36.6448 30.0801 34.1802C30.0801 34.098 30.0801 34.0159 30.0801 33.8516C30.4087 33.8516 30.7373 33.8516 31.0659 33.8516C31.1481 33.8516 31.2302 33.9337 31.2302 34.0159C32.709 35.7411 34.1878 37.4664 35.6666 39.1916C35.7487 39.2737 35.8309 39.3559 35.913 39.4381Z" fill="#1E262F" />
                        <path d="M39.9385 41.8205C39.9385 39.1916 39.9385 36.5627 39.9385 33.8516C40.0206 33.8516 40.1028 33.8516 40.1849 33.8516C41.2529 33.8516 42.321 33.8516 43.389 33.8516C45.3607 33.8516 46.9216 35.166 47.2502 37.0556C47.661 39.2738 46.3465 41.4919 43.8819 41.8205C43.7176 41.8205 43.5533 41.8205 43.4711 41.8205C42.321 41.8205 41.1708 41.8205 40.1028 41.8205C40.0206 41.9027 39.9385 41.8205 39.9385 41.8205ZM41.3351 35.0839V35.166C41.3351 36.8913 41.3351 38.6165 41.3351 40.3418C41.3351 40.5061 41.4173 40.5061 41.4994 40.5061C42.0745 40.5061 42.7317 40.5061 43.389 40.5061C44.0462 40.5061 44.6213 40.2596 45.1142 39.8488C45.8536 39.1916 46.1001 38.2879 46.0179 37.302C45.8536 36.0697 44.8677 35.0017 43.5533 35.0017C42.9782 35.0017 42.4853 35.0017 41.9923 35.0017C41.6637 35.0839 41.4994 35.0839 41.3351 35.0839Z" fill="#1E262F" />
                        <path d="M50.6183 35.0839C50.6183 35.8233 50.6183 36.4805 50.6183 37.1377C52.0149 37.1377 53.4115 37.1377 54.726 37.1377C54.726 37.5485 54.726 38.0414 54.726 38.4522C53.3294 38.4522 52.0149 38.4522 50.6183 38.4522C50.6183 39.1916 50.6183 39.8488 50.6183 40.5061C52.2614 40.5061 53.9045 40.5061 55.6297 40.5061C55.6297 40.9168 55.6297 41.3276 55.6297 41.7384L55.5476 41.8205C55.4654 41.8205 55.3833 41.8205 55.3833 41.8205C53.4116 41.8205 51.4398 41.8205 49.4681 41.8205C49.386 41.8205 49.3038 41.8205 49.2217 41.8205C49.2217 39.1916 49.2217 36.5627 49.2217 33.8516C51.2755 33.8516 53.4115 33.8516 55.5476 33.8516C55.5476 34.2623 55.5476 34.591 55.5476 35.0017L55.3833 35.0839C54.9725 35.0839 54.5617 35.0839 54.2331 35.0839C53.1651 35.0839 52.0971 35.0839 51.0291 35.0839C50.8648 35.0839 50.7826 35.0839 50.6183 35.0839Z" fill="#1E262F" />
                        <path d="M87.3414 33.8516C87.3414 34.2623 87.3414 34.591 87.3414 35.0017C87.3414 35.0017 87.2592 35.0839 87.1771 35.166H87.0128C85.534 35.166 84.1374 35.166 82.6586 35.166C82.33 35.166 82.33 35.166 82.33 35.4946C82.33 36.0697 82.33 36.6448 82.33 37.302C83.7266 37.302 85.1232 37.302 86.4377 37.302C86.4377 37.7128 86.4377 38.2057 86.4377 38.6165C85.0411 38.6165 83.7266 38.6165 82.33 38.6165C82.33 38.7808 82.33 38.9451 82.33 39.0273C82.33 39.5202 82.33 40.0131 82.33 40.5061C82.33 40.6704 82.33 40.7525 82.5764 40.7525C84.0552 40.7525 85.534 40.7525 87.0949 40.7525C87.1771 40.7525 87.2592 40.7525 87.4235 40.7525C87.4235 41.1633 87.4235 41.5741 87.4235 41.9027L87.3414 41.9848C87.3414 41.9848 87.2592 41.9848 87.1771 41.9848C85.2054 41.9848 83.2337 41.9848 81.3441 41.9848C81.262 41.9848 81.1798 41.9848 81.0977 41.9848C81.0977 39.2738 81.0977 36.6448 81.0977 34.0159C83.1515 33.8516 85.2054 33.8516 87.3414 33.8516Z" fill="#1E262F" />
                        <path d="M20.2217 41.9027C21.2897 39.1916 22.3577 36.5627 23.4257 33.8516C24.0008 33.8516 24.5759 33.8516 25.1509 33.8516C25.2331 33.8516 25.3152 33.9337 25.3152 34.0159C25.8903 35.3303 26.3833 36.727 26.9583 38.0414C27.4513 39.1916 27.862 40.3418 28.355 41.4919C28.355 41.5741 28.4371 41.6562 28.4371 41.7384C28.0263 41.7384 27.5334 41.7384 27.1226 41.7384C27.0405 41.7384 26.9583 41.6562 26.9583 41.5741C26.794 41.0811 26.5476 40.5882 26.3833 40.0953C26.3833 39.931 26.3011 39.931 26.1368 39.931C24.9045 39.931 23.6722 39.931 22.4398 39.931C22.2755 39.931 22.1934 39.931 22.1934 40.0953C22.0291 40.5882 21.7826 41.0811 21.6183 41.5741C21.5361 41.7384 21.454 41.8205 21.2897 41.8205C20.9611 41.8205 20.6325 41.9027 20.2217 41.9027ZM24.4116 35.0839C24.3294 35.0839 24.3294 35.0839 24.4116 35.0839C23.8365 36.3162 23.3435 37.5485 22.8506 38.863C23.9186 38.863 24.9045 38.863 25.8903 38.863C25.3974 37.5485 24.9045 36.3162 24.4116 35.0839Z" fill="#1E262F" />
                        <path d="M11.7598 33.8516C12.1705 33.8516 12.6635 33.8516 13.1564 33.8516C13.1564 35.0017 13.1564 36.0697 13.1564 37.1377C14.7173 37.1377 16.1961 37.1377 17.757 37.1377C17.757 35.9876 17.757 34.9196 17.757 33.8516C18.25 33.8516 18.6607 33.8516 19.1537 33.8516C19.1537 33.9337 19.1537 34.0159 19.1537 34.098C19.1537 35.7411 19.1537 37.3842 19.1537 39.0273C19.1537 39.1916 19.1537 39.3559 18.9893 39.4381C18.6607 40.0131 18.25 40.5061 17.9213 40.999C17.9213 40.999 17.8392 41.0811 17.757 41.1633C17.757 40.2596 17.757 39.4381 17.757 38.5344C16.1961 38.5344 14.7173 38.5344 13.1564 38.5344C13.1564 39.6845 13.1564 40.7525 13.1564 41.8205C12.6635 41.8205 12.2527 41.8205 11.7598 41.8205C11.7598 39.1916 11.7598 36.5627 11.7598 33.8516Z" fill="#1E262F" />
                        <path d="M79.044 40.5882C79.044 40.999 79.044 41.4098 79.044 41.8205C76.6616 41.8205 74.2791 41.8205 71.8145 41.8205C73.3754 39.6024 74.9363 37.3842 76.4972 35.0839C75.0185 35.0839 73.6219 35.0839 72.1431 35.0839C72.1431 34.6731 72.1431 34.2623 72.1431 33.8516C74.4434 33.8516 76.6616 33.8516 78.9619 33.8516C77.4009 36.0697 75.84 38.2879 74.2791 40.5882C75.9222 40.5882 77.4831 40.5882 79.044 40.5882Z" fill="#1E262F" />
                        <path d="M96.7895 41.8205C96.2966 41.8205 95.8858 41.8205 95.475 41.8205C95.475 39.6024 95.475 37.3842 95.475 35.0839C94.4892 35.0839 93.5855 35.0839 92.5996 35.0839C92.5996 34.6731 92.5996 34.2623 92.5996 33.8516C94.9821 33.8516 97.2824 33.8516 99.6649 33.8516C99.6649 34.2623 99.6649 34.591 99.6649 35.0017C99.6649 35.0017 99.5006 35.0839 99.4184 35.0839C98.679 35.0839 97.8575 35.0839 97.1181 35.0839C96.7073 35.0839 96.7895 35.0839 96.7895 35.4125C96.7895 37.4664 96.7895 39.4381 96.7895 41.4919C96.7895 41.6562 96.7895 41.7384 96.7895 41.8205Z" fill="#1E262F" />
                        <path d="M59.2453 40.5882C60.724 40.5882 62.0385 40.5882 63.4351 40.5882C63.4351 40.999 63.4351 41.4098 63.4351 41.8205C61.5456 41.8205 59.7382 41.8205 57.8486 41.8205C57.8486 39.1916 57.8486 36.5627 57.8486 33.8516C58.2594 33.8516 58.7523 33.8516 59.2453 33.8516C59.2453 36.0697 59.2453 38.2879 59.2453 40.5882Z" fill="#1E262F" />
                        <path d="M89.8066 33.8516C90.2996 33.8516 90.7103 33.8516 91.1211 33.8516C91.1211 36.4805 91.1211 39.1094 91.1211 41.8205C90.7103 41.8205 90.2174 41.8205 89.8066 41.8205C89.8066 39.1916 89.8066 36.5627 89.8066 33.8516Z" fill="#1E262F" />
                    </g>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1248_10072">
                    <rect width="138.852" height="75.582" fill="white" transform="translate(0.416992 0.0898438)" />
                </clipPath>
                <clipPath id="clip1_1248_10072">
                    <rect width="138.852" height="75.582" fill="white" transform="translate(0.416992 0.0898438)" />
                </clipPath>
                <clipPath id="clip2_1248_10072">
                    <rect width="138.841" height="75.582" fill="white" transform="translate(0.421875 0.0898438)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default HANDELSZEITUNG
