import React from 'react'
import Arrow from '../assets/Arrow'

const ShowmoreCards = ({ unserestory, text }) => {
    return (
        <div className='border border-[0.9ps solid #F2F2F2] rounded-[3.6px]'>
            {unserestory ? (
                <div className='uneserestory'>

                </div>
            ) : (
                <div className='propchain'>

                </div>
            )}
            <div className='py-[25px] px-[20px]'>
                <div className='flex flex-row justify-between items-end text-[#1D2123] font-medium text-[17.72px] lg:text-[10px]  xl:text-[12px] 2xl:text-[16px] 3xl:text-[17.72px]'>
                    <div className='w-full flex flex-wrap max-w-[50px]'>
                        {text}
                    </div>
                    <div>
                        <Arrow />
                    </div>
                </div>

            </div>


        </div>
    )
}

export default ShowmoreCards
