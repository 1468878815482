import React from 'react'

function SectionBreaker({children}) {
    return (
        <div className='pt-[110px] lg:pt-[120px]'>
            {children}
        </div>
    )
}

export default SectionBreaker