import React from 'react'

const SEFGROWTH = ({ width, height }) => {
    return (
        <svg width={width || "139"} height={height || "76"} viewBox="0 0 139 76" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1248_10109)">
                <g clip-path="url(#clip1_1248_10109)">
                    <g clip-path="url(#clip2_1248_10109)">
                        <path d="M2.83743 0.503906H135.927C137.16 0.503906 138.228 1.48976 138.228 2.80423V73.0462C138.228 74.2785 137.242 75.3465 135.927 75.3465H2.83743C1.60512 75.3465 0.537109 74.3607 0.537109 73.0462V2.80423C0.537109 1.48976 1.52296 0.503906 2.83743 0.503906Z" fill="white" />
                        <path d="M2.83743 0.503906H135.927C137.16 0.503906 138.228 1.48976 138.228 2.80423V73.0462C138.228 74.2785 137.242 75.3465 135.927 75.3465H2.83743C1.60512 75.3465 0.537109 74.3607 0.537109 73.0462V2.80423C0.537109 1.48976 1.52296 0.503906 2.83743 0.503906Z" stroke="#E0E0E0" stroke-width="0.821544" />
                        <mask id="mask0_1248_10109" maskUnits="userSpaceOnUse" x="16" y="24" width="108" height="22">
                            <path d="M123.029 24H16.1465V45.1958H123.029V24Z" fill="white" />
                        </mask>
                        <g mask="url(#mask0_1248_10109)">
                            <path d="M22.2259 41.335C22.2259 42.1565 21.5687 42.5673 20.172 42.5673C18.9397 42.5673 17.7074 42.0744 17.1323 41.8279L16.1465 44.3747C16.8037 44.7033 18.4468 45.1962 20.3364 45.1962C23.2118 45.1962 25.2656 43.9639 25.2656 41.0063C25.2656 36.7343 19.4327 37.2272 19.4327 35.2555C19.4327 34.5162 20.0899 34.1875 20.9936 34.1875C22.2259 34.1875 23.4582 34.5983 23.9511 34.9269L24.937 32.3801C24.0333 31.8051 22.5545 31.5586 20.9936 31.5586C18.3647 31.5586 16.3929 32.7088 16.3929 35.4198C16.3929 39.9383 22.2259 39.2811 22.2259 41.335Z" fill="#1E262F" />
                            <path d="M26.9912 44.9493H35.1245V42.3203H30.0309V39.7735H34.6316V37.0624H30.0309V34.5978H35.0423V31.8867H26.9912V44.9493Z" fill="#1E262F" />
                            <path d="M37.0137 44.9493H40.0534V39.9378H44.654V37.3089H40.0534V34.5978H45.0648V31.8867H37.0137V44.9493Z" fill="#1E262F" />
                            <path d="M45.8047 43.5531C45.8047 44.539 46.4619 45.1141 47.4478 45.1141C48.5158 45.1141 49.173 44.4568 49.173 43.5531C49.173 42.5673 48.5158 41.9922 47.4478 41.9922C46.5441 41.9922 45.8047 42.5673 45.8047 43.5531Z" fill="#1E262F" />
                            <path d="M57.1419 39.7733H60.1816V43.5524C59.6065 43.881 58.9493 44.0453 58.1278 44.0453C55.4988 44.0453 53.7736 42.3201 53.7736 38.6231C53.7736 34.5154 56.1561 33.2009 58.1278 33.2009C59.1958 33.2009 60.0995 33.3652 60.6745 33.6117L61.0032 32.4616C60.3459 32.2151 59.5244 32.0508 58.0456 32.0508C55.3345 32.0508 52.377 33.9403 52.377 38.6231C52.377 43.1416 54.6773 45.1955 57.8813 45.1955C59.1136 45.1955 60.5924 44.8669 61.3318 44.2918V38.7053H57.1419V39.7733Z" fill="#1E262F" />
                            <path d="M70.368 35.5833C70.368 37.3907 69.0535 38.2122 67.3283 38.2122H65.8495V33.283C66.1781 33.2008 67.0818 33.2008 67.7391 33.2008C69.3821 33.2008 70.368 34.1867 70.368 35.5833ZM68.8071 39.1981C70.4501 38.7873 71.7646 37.555 71.7646 35.5833C71.7646 33.0365 69.8751 32.1328 67.7391 32.1328C66.7532 32.1328 65.4387 32.215 64.6172 32.3793V44.9489H65.8495V39.3624H67.7391L71.3538 44.9489H72.8326L68.9714 39.1159" fill="#1E262F" />
                            <path d="M83.5954 38.5411C83.5954 42.238 82.1988 43.9633 79.8164 43.9633C77.1874 43.9633 76.0373 41.3343 76.0373 38.5411C76.0373 34.8442 77.4339 33.1189 79.8164 33.1189C82.4453 33.1189 83.5954 35.7478 83.5954 38.5411ZM84.9921 38.5411C84.9921 34.5977 83.349 31.9688 79.8164 31.9688C76.5302 31.9688 74.6406 34.3512 74.6406 38.5411C74.6406 42.4845 76.2837 45.1134 79.8164 45.1134C83.1025 45.1134 84.9921 42.8131 84.9921 38.5411Z" fill="#1E262F" />
                            <path d="M111.446 32.2148H102.162V33.365H106.188V44.8666H107.502V33.365H111.528V32.2148H111.446Z" fill="#1E262F" />
                            <path d="M121.715 32.2148V37.8013H115.307V32.2148H113.992V44.8666H115.307V38.9515H121.715V44.8666H123.029V32.2148H121.715Z" fill="#1E262F" />
                            <path d="M102.409 27.2862L101.751 24L99.5331 26.5468L100.437 26.7932L96.1648 42.3204L93.2072 34.9265H92.7143L89.7567 42.3204L88.6066 38.2127H87.21L89.1817 45.1137H89.8389L92.8786 37.3912L95.9183 45.1137H96.5756L101.587 27.0397L102.409 27.2862Z" fill="#1E262F" />
                        </g>
                    </g>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1248_10109">
                    <rect width="138.852" height="75.582" fill="white" transform="translate(0.121094 0.0898438)" />
                </clipPath>
                <clipPath id="clip1_1248_10109">
                    <rect width="138.852" height="75.582" fill="white" transform="translate(0.121094 0.0898438)" />
                </clipPath>
                <clipPath id="clip2_1248_10109">
                    <rect width="138.841" height="75.582" fill="white" transform="translate(0.126953 0.0898438)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default SEFGROWTH
