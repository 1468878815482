import React from 'react'

const BILANZ = ({ width, height }) => {
    return (
        <svg width={width || "140"} height={height || "76"} viewBox="0 0 140 76" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1248_10025)">
                <g clip-path="url(#clip1_1248_10025)">
                    <g clip-path="url(#clip2_1248_10025)">
                        <path d="M3.5679 0.503906H136.658C137.89 0.503906 138.958 1.48976 138.958 2.80423V73.0462C138.958 74.2785 137.972 75.3465 136.658 75.3465H3.5679C2.33558 75.3465 1.26758 74.3607 1.26758 73.0462V2.80423C1.26758 1.48976 2.25343 0.503906 3.5679 0.503906Z" fill="white" />
                        <path d="M3.5679 0.503906H136.658C137.89 0.503906 138.958 1.48976 138.958 2.80423V73.0462C138.958 74.2785 137.972 75.3465 136.658 75.3465H3.5679C2.33558 75.3465 1.26758 74.3607 1.26758 73.0462V2.80423C1.26758 1.48976 2.25343 0.503906 3.5679 0.503906Z" stroke="#E0E0E0" stroke-width="0.821544" />
                        <path d="M35.1158 46.9221C37.0875 46.2649 38.7305 44.9504 38.7305 42.4858C38.7305 41.0891 38.0733 40.0211 37.1696 39.1996C36.2659 38.4602 35.1158 38.0494 33.9656 37.803C33.5548 37.7208 33.1441 37.6387 32.7333 37.6387V37.5565C35.1158 37.2279 38.0733 35.9134 38.0733 33.038C38.0733 31.6414 37.5804 30.6555 36.7588 29.9983C35.1979 28.6838 32.6511 28.5195 30.5973 28.5195H22.3818V29.2589H25.0108V46.6756H22.3818V47.415H28.5434C30.433 47.3329 33.0619 47.5793 35.1158 46.9221ZM28.3791 29.1768C30.6794 29.2589 32.569 28.9303 33.637 29.9162C34.2121 30.4912 34.5407 31.3949 34.5407 32.9559C34.5407 36.1599 33.0619 37.1457 30.9259 37.1457H28.872H28.3791V29.1768ZM28.3791 37.9673H31.008C32.2404 37.9673 33.1441 38.2959 33.7191 38.871C34.5407 39.6104 34.8693 40.8427 34.8693 42.3215C34.8693 44.2932 34.2942 46.6756 31.8296 46.6756H28.7899H28.2969V37.9673H28.3791Z" fill="#1E262F" />
                        <path d="M45.385 29.1768H48.0139V28.5195H39.3877V29.1768H42.0166V46.5935H39.3877V47.3329H48.0139V46.5935H45.385V29.1768Z" fill="#1E262F" />
                        <path d="M100.921 29.1768H103.632V28.5195H97.4704V29.1768H100.017V40.7605V41.1713L91.391 28.5195H84.6543V29.1768H87.2011V46.5935H84.8186V47.3329H90.7337V46.5935H88.1048V29.7518L88.5977 30.4912L100.099 47.6615H100.921V29.1768Z" fill="#1E262F" />
                        <path d="M118.749 41.2536H118.01C117.353 44.7862 116.449 46.5936 112.423 46.5936H107.576L118.749 29.259V28.4375H104.537V33.5311H105.358C105.851 30.82 107.166 29.752 108.891 29.3412C109.712 29.1769 110.78 29.1769 111.766 29.1769H114.888L114.642 29.5055L103.551 46.5936V47.333H118.749V41.2536Z" fill="#1E262F" />
                        <path d="M84.3261 46.5941H81.7793L74.7962 28.1094H73.9747L66.8272 46.6763H64.6091V41.4184V41.0898H63.7054C63.6232 44.3759 62.3088 46.7584 58.7761 46.7584H55.2435V29.1774H58.201V28.438H49.1641V29.1774H51.793V46.5941H49.1641V47.3335H70.6063V46.5941H67.7309L69.5383 41.5827H75.9464L76.1107 41.9113L77.9181 46.5941H74.9605V47.3335H84.244V46.5941H84.3261ZM72.9067 33.0386L75.6999 40.9255H69.867L72.9067 33.0386Z" fill="#1E262F" />
                    </g>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1248_10025">
                    <rect width="138.852" height="75.582" fill="white" transform="translate(0.851562 0.0898438)" />
                </clipPath>
                <clipPath id="clip1_1248_10025">
                    <rect width="138.852" height="75.582" fill="white" transform="translate(0.851562 0.0898438)" />
                </clipPath>
                <clipPath id="clip2_1248_10025">
                    <rect width="138.841" height="75.582" fill="white" transform="translate(0.857422 0.0898438)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default BILANZ
