import React from 'react'

const ResHeart = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1248_9786)">
                <path d="M10.1774 1.31799C9.07194 0.376805 7.41879 0.532993 6.39242 1.59183L5.99282 2.00563L5.59322 1.59386C4.76562 0.737864 3.09623 0.222646 1.80818 1.31799C0.53433 2.40523 0.467392 4.35657 1.60737 5.53305L5.53236 9.58583C5.65814 9.71568 5.82446 9.7826 5.99078 9.7826C6.1571 9.7826 6.32346 9.71767 6.4492 9.58583L10.3742 5.53305C11.5182 4.35657 11.4513 2.40523 10.1774 1.31799ZM9.91172 5.08273L6.00092 9.13555L2.0739 5.08273C1.29499 4.27949 1.13271 2.74803 2.23009 1.81293C3.34167 0.863626 4.64797 1.55126 5.12666 2.0462L5.99282 2.94073L6.85894 2.0462C7.32953 1.55937 8.64804 0.869711 9.75554 1.81293C10.8509 2.746 10.6907 4.27746 9.91172 5.08273Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_1248_9786">
                    <rect width="10.8" height="10.8" fill="white" transform="translate(0.799805 0.695312)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default ResHeart
