import React from 'react'

const Container = ({children}) => {
  return (
    <div className='w-full max-w-[350px] xs:max-w-[450px] sm:max-w-[550px] md:max-w-[650px] lg:max-w-[900px] xl:max-w-[1000px]  2xl:max-w-[1200px] 3xl:max-w-[1300px] mx-auto'>
      {children}
    </div>
  )
}

export default Container
