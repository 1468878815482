import React from 'react'

const FooterTexts = ({children}) => {
  return (
    <div className='text-[16.7px] lg:text-[12.6px] font-light text-[#EAEAEB]'>
      {children}
    </div>
  )
}

export default FooterTexts
