import React from 'react'

const Search = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.2467 21.4775L17.8532 16.0851C19.4165 14.2083 20.196 11.8011 20.0296 9.36419C19.8633 6.92731 18.7637 4.64838 16.9599 3.00147C15.1561 1.35456 12.7868 0.466487 10.3448 0.521984C7.90292 0.577481 5.57639 1.57228 3.84924 3.29943C2.12208 5.02658 1.12729 7.35312 1.07179 9.79508C1.01629 12.237 1.90437 14.6063 3.55127 16.4101C5.19818 18.214 7.47711 19.3134 9.914 19.4798C12.3509 19.6462 14.7581 18.8667 16.6349 17.3034L22.0273 22.697C22.1074 22.777 22.2024 22.8405 22.307 22.8838C22.4117 22.9272 22.5238 22.9495 22.637 22.9495C22.7503 22.9495 22.8623 22.9272 22.967 22.8838C23.0716 22.8405 23.1666 22.777 23.2467 22.697C23.3268 22.6169 23.3903 22.5218 23.4337 22.4172C23.4769 22.3126 23.4993 22.2004 23.4993 22.0872C23.4993 21.974 23.4769 21.8619 23.4337 21.7572C23.3903 21.6527 23.3268 21.5576 23.2467 21.4775ZM2.81666 10.0227C2.81666 8.4887 3.27152 6.98919 4.12374 5.71376C4.97597 4.43833 6.18725 3.44424 7.60443 2.85722C9.02162 2.27021 10.581 2.11662 12.0855 2.41588C13.59 2.71514 14.972 3.4538 16.0566 4.53847C17.1413 5.62314 17.88 7.00509 18.1793 8.50957C18.4785 10.0141 18.3249 11.5735 17.7379 12.9907C17.1508 14.4079 16.1567 15.6191 14.8813 16.4713C13.6059 17.3236 12.1064 17.7785 10.5724 17.7785C8.51618 17.7762 6.5448 16.9583 5.0908 15.5043C3.6368 14.0503 2.81894 12.0789 2.81666 10.0227Z" fill="white" />
        </svg>
    )
}

export default Search
