import React from 'react';
import Container from './Container';
import { useWindowScroll } from "@uidotdev/usehooks";
import { useTranslation } from 'react-i18next';
import IMMOVERBAND from '../assets/IMMOVERBAND';
import Phone from '../assets/Phone';
import Menu from '../assets/Menu';

const Header = () => {
    const [{ y }] = useWindowScroll();
    const { i18n } = useTranslation();

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
    };

    return (
        <div className={`w-full fixed ${y > 50 ? 'bg-[#fff]' : 'bg-[transparent]'} top-0 right-0 py-[17px] lg:py-[10px] z-[1000]`}>
            <Container>
                <div className='flex flex-row justify-between items-center'>
                    <div className='flex lg:hidden'>
                        <IMMOVERBAND color={y > 50 && '#000'} />
                    </div>
                    <div className='flex flex-row items-center gap-[15px] lg:hidden'>
                        <div>
                            <Phone color={y > 50 && '#000'} />
                        </div>
                        <div>
                            <Menu color={y > 50 && '#000'} />
                        </div>
                    </div>
                    <div className='hidden lg:flex flex-row gap-[30px] items-center text-white'>
                        <div className="custom-select">
                            <select id="select" style={{ fontSize: '16px' }}
                                className={`w-[300px] flex gap-[10px] items-center ${y > 50 ? 'text-black' : 'text-[#EAEAEB]'} cursor-pointer bg-transparent outline-none`}>
                                <option value="buy" className="bg-transparent">Buy</option>
                                <option value="sell" className="bg-transparent">Sell</option>
                            </select>
                        </div>
                        <div className="resources-select">
                            <select id="select" style={{ fontSize: '16px' }}
                                className={`w-full max-w-[200px] flex gap-[10px] items-center ${y > 50 ? 'text-black' : 'text-[#EAEAEB]'} cursor-pointer bg-transparent outline-none`}>
                                <option value="resources" className="bg-transparent text-[16px]">Resources</option>
                            </select>
                        </div>
                        <div className={`text-[16px] ${y > 50 ? 'text-black' : 'text-[#EAEAEB]'} font-normal`}>
                            Feed
                        </div>
                    </div>
                    <div className={`hidden lg:flex flex-row gap-[10px] text-[14.29px] ${y > 50 ? 'text-black' : 'text-[#EAEAEB]'} cursor-pointer`}>
                        <div className={`${y > 50 ? 'border border-[0.9px] border-black' : 'border border-[0.9px] border-[#EAEAEB]'} bg-[transparent] py-[13px] px-[20px] rounded-[5.4px]`}>
                            Contact
                        </div>
                        <div className={`lang-select cursor-pointer ${y > 50 ? 'border border-[0.9px] border-black' : 'border border-[0.9px] border-[#EAEAEB]'} bg-[transparent] rounded-[5.4px]`}>
                            <select id="select" onChange={(e) => changeLanguage(e.target.value)}
                                className={`flex gap-[10px] items-center ${y > 50 ? 'text-black' : 'text-[#EAEAEB]'} cursor-pointer bg-transparent outline-none`}>
                                <option value="en" className="bg-transparent">EN</option>
                                <option value="de" className="bg-transparent">DE</option>
                            </select>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Header;
