import React from 'react'
import SectionBreaker from '../components/SectionBreaker'
import SecondContainer from '../components/SecondContainer'
import Questions from '../components/Questions'
import { useTranslation } from 'react-i18next'

const AskSection = () => {

    const { t } = useTranslation();
    return (
        <SectionBreaker>
            <SecondContainer>
                <div className='flex flex-col gap-[40px]'>
                    <div className='hidden lg:flex flex-col gap-[5px]'>
                        <div className=' text-[#30313A] text-[24px]  lg:text-[39.82px] font-semibold'>
                            {t('ask-title')}
                        </div>
                        <div className='text-[#30313A] font-normal text-[13.49px] lg:text-[14.06px] '>
                        {t('ask-desc')}
                        </div>
                    </div>
                    <div className='grid grid-cols-1 gap-[40px] lg:grid-cols-2 lg:gap-[70px]'>
                        <div className='flex flex-col gap-[20px]'>
                            <div className='text-[20px] lg:text-[22px] font-semibold text-[#30313A]'>
                            {t('ask-home')}
                            </div>
                            <div className='flex flex-col gap-[15px]'>
                                <Questions question={`${t('question1')}`} />
                                <Questions question={`${t('question2')}`} />
                                <Questions question={`${t('question3')}`} />
                                <Questions question={`${t('question4')}`} important={true} />
                            </div>
                        </div>
                        <div className='flex flex-col gap-[20px]'>
                            <div className='text-[20px] lg:text-[22px] font-semibold text-[#30313A]'>
                            {t('look-home')}
                            </div>
                            <div className='flex flex-col gap-[15px]'>
                                <Questions question={`${t('question5')}`} />
                                <Questions question={`${t('question6')}`} />
                                <Questions question={`${t('question7')}`} />
                                <Questions question={`${t('question8')}`} important={true} />
                            </div>
                        </div>
                    </div>
                </div>
            </SecondContainer>
        </SectionBreaker>
    )
}

export default AskSection
