import React from 'react'

const Menu = ({ color }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 6L20 6M4 12H20M4 18L20 18" stroke={color || "white"} stroke-width="2" stroke-linecap="round" />
        </svg>
    )
}

export default Menu
