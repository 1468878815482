import React from 'react'

const Offerscard = ({svg, title, text}) => {
    return (
        <div className='border  border-[1px solid #E1E1E1] rounded-[3.6px] flex flex-col'>
            <div className='flex justify-center items-center p-[17px] lg:p-[30px] px-[40px] lg:px-[0px]'>
                {svg}
            </div>
            <div className='flex flex-col bg-[#F6F6F6] py-[15px] px-[12px] gap-[8px] lg:h-[120px]'>
                <div className='text-[#1D2123] text-[14px] text-center lg:text-start xl:text-[17px] 2xl:text-[17.72px] font-semibold'>
                    {title}
                </div>
                <div className='hidden lg:flex text-[#1D2123] text-[14px] font-normal '>
                    {text}
                </div>
            </div>
        </div>
    )
}

export default Offerscard
