import React from 'react'

const SRF = ({ width, height }) => {
    return (
        <svg width={width || "139"} height={height || "76"} viewBox="0 0 139 76" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1248_10014)">
                <g clip-path="url(#clip1_1248_10014)">
                    <g clip-path="url(#clip2_1248_10014)">
                        <path d="M2.71634 0.503906H135.806C137.039 0.503906 138.107 1.48976 138.107 2.80423V73.0462C138.107 74.2785 137.121 75.3465 135.806 75.3465H2.71634C1.48402 75.3465 0.416016 74.3607 0.416016 73.0462V2.80423C0.416016 1.48976 1.40187 0.503906 2.71634 0.503906Z" fill="white" />
                        <path d="M2.71634 0.503906H135.806C137.039 0.503906 138.107 1.48976 138.107 2.80423V73.0462C138.107 74.2785 137.121 75.3465 135.806 75.3465H2.71634C1.48402 75.3465 0.416016 74.3607 0.416016 73.0462V2.80423C0.416016 1.48976 1.40187 0.503906 2.71634 0.503906Z" stroke="#E0E0E0" stroke-width="0.821544" />
                        <path d="M100.399 17.5078H38.3721V58.1742H100.399V17.5078Z" fill="#1E262F" />
                        <path d="M84.6247 35.9962V31.8885H92.5115C93.0866 31.8885 93.333 31.642 93.333 31.0669V28.9309C93.333 28.3558 93.1687 28.1094 92.5115 28.1094H81.0921C80.517 28.1094 80.2705 28.3558 80.2705 28.9309V46.5941C80.2705 47.1692 80.4348 47.4156 81.0921 47.4156H83.8853C84.4604 47.4156 84.7068 47.1692 84.7068 46.5941V39.611H91.8543C92.4294 39.611 92.6758 39.3645 92.6758 38.7894V36.6534C92.6758 36.0783 92.5115 35.8319 91.8543 35.8319L84.6247 35.9962Z" fill="white" />
                        <path d="M69.1805 37.5569L68.8519 38.7892C68.7697 39.1178 68.7697 39.3643 68.7697 39.4464C68.7697 39.775 68.8519 40.0215 69.0984 40.3501L72.631 46.7582C72.9596 47.2511 73.2061 47.4976 73.8633 47.4976H77.1495C77.5603 47.4976 78.0532 47.2511 77.6424 46.5117L73.2882 39.2821C74.767 38.3784 76.4101 37.064 76.4101 34.1064C76.4101 30.3273 74.2741 28.2734 69.6734 28.2734H62.9368C62.3617 28.2734 62.1152 28.5199 62.1152 29.095V46.7582C62.1152 47.3332 62.2795 47.5797 62.9368 47.5797H65.73C66.3051 47.5797 66.5516 47.3332 66.5516 46.7582V31.8061H69.427C71.2344 31.8061 72.1381 32.4633 72.1381 34.1886C72.1381 35.7495 71.4808 36.2424 70.7414 36.4889C69.7556 36.8175 69.427 36.4067 69.1805 37.5569Z" fill="white" />
                        <path d="M45.9303 44.4581L45.6017 46.7584C45.5195 47.2513 45.766 47.4156 46.1768 47.4156C47.0805 47.4978 49.2165 47.6621 51.3525 47.6621C56.1174 47.6621 59.6501 46.1012 59.6501 41.6648C59.6501 39.0359 58.4999 37.0642 54.5565 36.0783L52.3383 35.5033C50.8596 35.0925 50.1202 34.5996 50.1202 33.5316C50.1202 31.9706 51.5989 31.642 52.9134 31.642C54.803 31.642 56.6925 31.8063 57.4319 31.8885C58.2534 31.9706 58.4178 31.8885 58.5821 31.2312L58.9107 29.0131C58.9928 28.6023 58.7464 28.3558 58.3356 28.3558C57.4319 28.2737 55.5424 28.1094 53.0777 28.1094C48.1485 28.1094 45.6838 30.2454 45.6838 33.6959C45.6838 36.8177 47.3269 38.6251 50.2845 39.3645L52.5026 39.9396C54.31 40.4325 55.1316 40.9255 55.1316 42.0756C55.1316 43.7187 53.9814 44.0473 51.1882 44.0473C49.9559 44.0473 47.7377 43.883 46.9983 43.8009C46.2589 43.7187 46.0125 43.8009 45.9303 44.4581Z" fill="white" />
                    </g>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1248_10014">
                    <rect width="138.852" height="75.582" fill="white" transform="translate(0 0.0898438)" />
                </clipPath>
                <clipPath id="clip1_1248_10014">
                    <rect width="138.852" height="75.582" fill="white" transform="translate(0 0.0898438)" />
                </clipPath>
                <clipPath id="clip2_1248_10014">
                    <rect width="138.841" height="75.582" fill="white" transform="translate(0.00585938 0.0898438)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default SRF
