import React from 'react'
import Importants from './Importants'
import Favorite from '../assets/Favorite'
import ResHeart from '../assets/ResHeart'
const HomeCards = ({ img, price, address, beds, baths, sqft, Importants1, Importants2, Importants3, time, day }) => {
    return (
        <div className='flex flex-col gap-[5px] '>
            <div className='rounded-[7.2px] relative pb-[10px]'>
                <img src={img} alt='home1' className='w-full h-auto lg:h-[259.2px] lg:w-[259.2px]' />
                {(time && day) && (
                    <div className='absolute top-[10px] left-[13px] lg:top-[10px] lg:left-[10px] flex flex-row gap-[5px]'>
                        <Importants day={day} />
                        <Importants timer={time} />
                    </div>
                )}
                {(time && day) && (
                    <div className='hidden lg:block absolute top-[17px] right-[20px] flex flex-row gap-[5px]'>
                        <Favorite />
                    </div>
                )}
                {(time && day) && (
                    <div className='absolute top-[17px] right-[20px] flex flex-row gap-[5px] lg:hidden'>
                        <ResHeart />
                    </div>
                )}
            </div>
            <div className='text-[#30313A] text-[24px] lg:text-[20px] font-medium '>
                {price}
            </div>
            <div className='text-[#30313A] text-[14px] font-normal '>
                {address}
            </div>
            <div className='flex flex-row'>
                <div className='border-r-[1px] pr-[10px] lg:text-[12px]  text-[18px] 2xl:text-[14px]  font-normal'>
                    {beds}
                </div>
                <div className='px-[10px] text-[18px] lg:text-[12px] 2xl:text-[14px]  font-normal'>
                    {baths}
                </div>
                <div className=' border-l-[1px] pl-[10px] text-[18px] lg:text-[12px]  2xl:text-[14px]  font-normal'>
                    {sqft}
                </div>
            </div>
            <div className='flex flex-row flex-wrap  gap-[5px] w-full max-w-[300px]' >
                <Importants text={Importants1} />
                <Importants text={Importants2} />
                <Importants text={Importants3} />
            </div>
        </div>
    )
}

export default HomeCards
