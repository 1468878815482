import React from 'react'

const Linkedin = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1.05059" y="0.60332" width="26.1" height="26.1" rx="13.05" stroke="#EAEAEB" stroke-width="0.9" />
            <g clip-path="url(#clip0_1248_10300)">
                <path d="M11.3155 17.6031H9.35629V11.294H11.3155V17.6031ZM10.3348 10.4333C9.70834 10.4333 9.2002 9.91444 9.2002 9.28795C9.2002 8.98703 9.31974 8.69843 9.53252 8.48565C9.74531 8.27286 10.0339 8.15332 10.3348 8.15332C10.6358 8.15332 10.9243 8.27286 11.1371 8.48565C11.3499 8.69843 11.4695 8.98703 11.4695 9.28795C11.4695 9.91444 10.9611 10.4333 10.3348 10.4333ZM18.6481 17.6031H16.6931V14.5319C16.6931 13.7999 16.6784 12.8612 15.6745 12.8612C14.6559 12.8612 14.4998 13.6565 14.4998 14.4791V17.6031H12.5427V11.294H14.4217V12.1546H14.4492C14.7107 11.6589 15.3497 11.1358 16.3029 11.1358C18.2857 11.1358 18.6502 12.4415 18.6502 14.1374V17.6031H18.6481Z" fill="#EAEAEB" />
            </g>
            <defs>
                <clipPath id="clip0_1248_10300">
                    <rect width="10.8" height="10.8" fill="white" transform="translate(9.2002 8.15332)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default Linkedin
