import React from 'react'

const Questions = ({ question, important }) => {
    return (
        <div className={`flex border ${important ? 'border-[1px solid #28333E] bg-[#1E262F] text-[#EAEAEB]' : 'border-[1px solid #EBECEE] bg-[#F9F9FA] text-[#30313A]'}  font-normal text-[14px]  lg:text-[14.29px] w-full rounded-[5.4px] py-[10px] px-[15px]`}>
            {question}
        </div>
    )
}

export default Questions
