import React from 'react'

const Kaufen = ({ color }) => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.01361 5.30419V20.5481H8.11398C8.31184 20.5481 8.47545 20.6128 8.60482 20.7421C8.73419 20.8714 8.79888 21.0274 8.79888 21.2099C8.79888 21.4077 8.73419 21.5712 8.60482 21.7006C8.47545 21.8299 8.31184 21.8945 8.11398 21.8945H2.47494C2.27708 21.8945 2.11347 21.8299 1.98409 21.7006C1.85472 21.5712 1.79004 21.4077 1.79004 21.2099C1.79004 21.0274 1.85472 20.8714 1.98409 20.7421C2.11347 20.6128 2.27708 20.5481 2.47494 20.5481H4.6438V5.23573C4.6438 4.52069 4.89493 3.91596 5.39719 3.42152C5.89946 2.92708 6.50826 2.67986 7.2236 2.67986H21.3098C21.5076 2.67986 21.6712 2.75593 21.8006 2.90806C21.93 3.0602 21.9947 3.23515 21.9947 3.43293C21.9947 3.61549 21.93 3.77523 21.8006 3.91215C21.6712 4.04907 21.5076 4.11753 21.3098 4.11753H17.9994V5.53239H19.0496C19.6584 5.53239 20.1492 5.75679 20.5221 6.20559C20.895 6.65439 21.0815 7.16024 21.0815 7.72314V13.2228C21.0815 13.7857 20.895 14.2916 20.5221 14.7404C20.1492 15.1892 19.6584 15.4136 19.0496 15.4136H9.48378C8.87498 15.4136 8.38413 15.1892 8.01124 14.7404C7.63835 14.2916 7.4519 13.7857 7.4519 13.2228V7.72314C7.4519 7.16024 7.63835 6.65439 8.01124 6.20559C8.38413 5.75679 8.87498 5.53239 9.48378 5.53239H10.534V4.11753H7.2236C6.87354 4.11753 6.58436 4.23164 6.35606 4.45984C6.12776 4.68804 6.01361 4.96949 6.01361 5.30419ZM16.5611 4.11753H11.8809V5.46393H16.5611V4.11753ZM12.7941 6.97007H9.39246C9.28592 6.97007 9.16036 7.03092 9.01577 7.15263C8.87117 7.27434 8.79888 7.46451 8.79888 7.72314V13.2228C8.79888 13.4967 8.87117 13.6906 9.01577 13.8047C9.16036 13.9188 9.28592 13.9759 9.39246 13.9759H19.0496C19.1714 13.9759 19.3045 13.9074 19.4491 13.7705C19.5937 13.6336 19.666 13.451 19.666 13.2228V7.72314C19.666 7.46451 19.5937 7.27434 19.4491 7.15263C19.3045 7.03092 19.1714 6.97007 19.0496 6.97007H12.7941Z" fill={color || "#282828"} />
        </svg>
    )
}

export default Kaufen
