import React from 'react'

const Facebook = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.450977" y="0.60332" width="26.1" height="26.1" rx="13.05" stroke="#EAEAEB" stroke-width="0.9" />
            <g clip-path="url(#clip0_1248_10294)">
                <path d="M18.8307 13.5525C18.8307 10.6627 16.4893 8.32129 13.5994 8.32129C10.7096 8.32129 8.36816 10.6627 8.36816 13.5525C8.36816 16.1635 10.2812 18.3277 12.782 18.7205V15.0648H11.4531V13.5525H12.782V12.4C12.782 11.089 13.5625 10.3649 14.7579 10.3649C15.3304 10.3649 15.929 10.4669 15.929 10.4669V11.7537H15.2692C14.6195 11.7537 14.4168 12.157 14.4168 12.5706V13.5525H15.8676L15.6356 15.0648H14.4168V18.7205C16.9177 18.3277 18.8307 16.1635 18.8307 13.5525Z" fill="#EAEAEB" />
            </g>
            <defs>
                <clipPath id="clip0_1248_10294">
                    <rect width="10.8" height="10.8" fill="white" transform="translate(8.2002 8.15332)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default Facebook
