import React, { useState } from 'react'
import Kaufen from '../assets/Kaufen';
import Mieten from '../assets/Mieten';
import Bewerten from '../assets/Bewerten';
import Immobilienpreise from '../assets/Immobilienpreise';
import Search from '../assets/Search';
import SecondContainer from '../components/SecondContainer';
import { useTranslation } from 'react-i18next';

const HeroSection = () => {

    const { t } = useTranslation();

    const [isClicked, setIsClicked] = useState('Kaufen');

    const handleClick = (buttonName) => {
        setIsClicked(buttonName);
    };
    return (
        <div className='bg-div flex justify-center items-center'>
            <SecondContainer>
                <div className='flex justify-center items-center flex-col gap-[40px]'>
                    <div className='text-white text-[43px] lg:text-[60px] font-semibold w-full max-w-[390px] lg:max-w-[900px] text-center'>
                        {t('hero')}
                    </div>
                    <div className='flex flex-row border items-center border-[0.97px solid #C7C7C7] bg-[transparent] rounded-[3.89px] w-full lg:max-w-[584.11px]'>
                        <div className={`flex flex-col  w-full justify-center items-center text-[14px] lg:flex-row gap-[7px] cursor-pointer py-[10px] px-[19px] lg:py-[20px]  rounded-[3.89px] lg:text-[15.21px]  ${isClicked === 'Kaufen' ? 'bg-white text-black' : 'bg-transparent  text-white'}`} onClick={() => handleClick('Kaufen')}>
                            <Kaufen color={isClicked !== 'Kaufen' && '#fff'} /><div className={` ${isClicked === 'Kaufen' ? 'flex' : ' hidden lg:flex'}`}>Kaufen</div>
                        </div>
                        <div className={`flex flex-col  w-full justify-center  items-center text-[14px] lg:flex-row gap-[7px] cursor-pointer   py-[10px] px-[19px] lg:py-[20px] rounded-[3.89px] lg:text-[15.21px]  ${isClicked === 'Mieten' ? 'bg-white text-black' : 'bg-transparent  text-white'}`} onClick={() => handleClick('Mieten')}>
                            <Mieten color={isClicked === 'Mieten' && '#000'} /> <div className={` ${isClicked === 'Mieten' ? 'flex' : ' hidden lg:flex'}`}>Mieten</div>
                        </div>
                        <div className={`flex flex-col  w-full justify-center  items-center text-[14px] lg:flex-row gap-[7px] cursor-pointer   py-[10px] px-[19px] lg:py-[20px]  rounded-[3.89px] lg:text-[15.21px]  ${isClicked === 'Bewerten' ? 'bg-white text-black' : 'bg-transparent  text-white'}`} onClick={() => handleClick('Bewerten')}>
                            <Bewerten color={isClicked === 'Bewerten' && '#000'} /><div className={` ${isClicked === 'Bewerten' ? 'flex' : ' hidden lg:flex'}`}>Bewerten</div>
                        </div>
                        <div className={`flex flex-col w-full justify-center items-center text-[14px] lg:flex-row gap-[7px] cursor-pointer    py-[10px] px-[19px] lg:py-[20px]  rounded-[3.89px] lg:text-[15.21px]  ${isClicked === 'Immobilienpreise' ? 'bg-white text-black' : 'bg-transparent  text-white'}`} onClick={() => handleClick('Immobilienpreise')}>
                            <Immobilienpreise color={isClicked === 'Immobilienpreise' && '#000'} /> <div className={` ${isClicked === 'Immobilienpreise' ? 'flex' : ' hidden lg:flex'}`}>Immobilienpreise</div>
                        </div>
                    </div>
                    <div className='bg-white py-[10px] flex flex-row gap-[20px] rounded-[3.89px] w-full  lg:max-w-[584.11px] px-[10px] items-center'>
                        <div className='w-full'>
                            <input className='search border-none w-full' placeholder='Ort, Region, PLZ eingeben...' />
                        </div>
                        <div className='p-[15px] bg-[#1D2123] rounded-[3.89px] cursor-pointer '>
                            <Search />
                        </div>
                    </div>
                </div>

            </SecondContainer>
        </div>

    )
}

export default HeroSection
