import React from 'react'

const SecondContainer = ({children}) => {
  return (
    <div className='w-full max-w-[350px] xs:max-w-[450px] sm:max-w-[550px] md:max-w-[650px] lg:max-w-[750px] xl:max-w-[850px] 2xl:max-w-[1000px] 3xl:max-w-[1100px] mx-auto'>
      {children}
    </div>
  )
}

export default SecondContainer
