import React from 'react'

const Phone = ({ color }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.6638 18.7715C20.6638 18.7715 19.5052 19.9095 19.2212 20.2432C18.7587 20.7367 18.2137 20.9698 17.4992 20.9698C17.4305 20.9698 17.3573 20.9698 17.2886 20.9653C15.9284 20.8784 14.6644 20.3483 13.7164 19.8958C11.1243 18.6435 8.84819 16.8657 6.95678 14.6125C5.3951 12.7341 4.35093 10.9974 3.6594 9.13265C3.23349 7.99464 3.07778 7.10799 3.14647 6.27162C3.19227 5.73689 3.39835 5.29357 3.77847 4.91423L5.34015 3.35574C5.56455 3.14551 5.8027 3.03125 6.03626 3.03125C6.32478 3.03125 6.55835 3.20492 6.7049 3.35117C6.70948 3.35574 6.71406 3.36031 6.71864 3.36489C6.998 3.62539 7.26362 3.89504 7.54298 4.18298C7.68495 4.32923 7.8315 4.47548 7.97805 4.6263L9.22831 5.874C9.71376 6.35846 9.71376 6.80635 9.22831 7.29081C9.0955 7.42335 8.96727 7.55589 8.83446 7.68386C8.44976 8.0769 8.75196 7.77532 8.35353 8.13181C8.34437 8.14095 8.33521 8.14552 8.33063 8.15466C7.93677 8.54771 8.01005 8.93162 8.09248 9.19213C8.09706 9.20584 8.10164 9.21955 8.10622 9.23326C8.43138 10.0194 8.88935 10.7598 9.58547 11.6418L9.59005 11.6464C10.854 13.2003 12.1867 14.4115 13.6568 15.3392C13.8446 15.4581 14.0369 15.554 14.2201 15.6454C14.385 15.7277 14.5407 15.8054 14.6735 15.8877C14.6918 15.8968 14.7101 15.9105 14.7285 15.9197C14.8842 15.9974 15.0307 16.0339 15.1819 16.0339C15.562 16.0339 15.8001 15.7963 15.878 15.7186L16.7757 14.8227C16.9314 14.6673 17.1787 14.48 17.4672 14.48C17.7511 14.48 17.9847 14.6582 18.1267 14.8136C18.1312 14.8182 18.1312 14.8182 18.1358 14.8227L20.6592 17.341C21.1309 17.8072 20.6638 18.7715 20.6638 18.7715Z" stroke={color || "white"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default Phone
