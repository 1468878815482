import React from 'react'

const NZZ = ({width, height}) => {
    return (
        <svg width={width || "140"} height={height || "76"} viewBox="0 0 140 76" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1248_10063)">
                <g clip-path="url(#clip1_1248_10063)">
                    <g clip-path="url(#clip2_1248_10063)">
                        <path d="M3.27298 0.503906H136.363C137.595 0.503906 138.663 1.48976 138.663 2.80423V73.0462C138.663 74.2785 137.677 75.3465 136.363 75.3465H3.27298C2.04066 75.3465 0.972656 74.3607 0.972656 73.0462V2.80423C0.972656 1.48976 1.95851 0.503906 3.27298 0.503906Z" fill="white" />
                        <path d="M3.27298 0.503906H136.363C137.595 0.503906 138.663 1.48976 138.663 2.80423V73.0462C138.663 74.2785 137.677 75.3465 136.363 75.3465H3.27298C2.04066 75.3465 0.972656 74.3607 0.972656 73.0462V2.80423C0.972656 1.48976 1.95851 0.503906 3.27298 0.503906Z" stroke="#E0E0E0" stroke-width="0.821544" />
                        <path d="M52.32 27.9403V28.1868H52.5665H52.6486C52.7308 28.1868 52.813 28.1868 52.9773 28.1868C53.3059 28.1868 53.6345 28.2689 54.1274 28.2689C54.8668 28.3511 55.1954 28.5976 55.4419 28.9262C55.6884 29.2548 55.6883 29.5834 55.7705 29.912C55.9348 31.0622 56.017 32.8696 56.017 35.8271V42.9746L42.215 26.1329L42.1329 26.0508H42.0507H36.4642H36.2178V26.2972V27.9403V28.1868H36.4642C36.4642 28.1868 36.4642 28.1868 36.5464 28.1868C36.6285 28.1868 36.6285 28.1868 36.7107 28.1868C36.875 28.1868 37.1215 28.1868 37.3679 28.2689C37.8609 28.3511 38.4359 28.5154 38.7646 28.7619C39.4218 29.2548 39.7504 29.7477 39.9147 30.3228C39.9969 30.8157 39.9969 31.3908 39.9969 32.2124C39.9969 32.3767 39.9969 32.541 39.9969 32.7053V40.5921C39.9969 43.221 39.9147 45.1927 39.8326 46.6715C39.8326 47.0823 39.7504 47.4109 39.5861 47.6574C39.4218 47.9038 39.2575 48.1503 38.8467 48.2325C38.5181 48.3146 38.1073 48.3968 37.6966 48.4789C37.5322 48.4789 37.2858 48.4789 37.2036 48.4789C37.1215 48.4789 37.1215 48.4789 37.0393 48.4789H36.7929V48.7254V50.3685V50.6149H37.0393H45.912H46.1584V50.3685V48.7254V48.4789H45.912H45.8298C45.8298 48.4789 45.7477 48.4789 45.6655 48.4789C45.5012 48.4789 45.3369 48.4789 45.0904 48.4789C44.5975 48.3968 44.1046 48.3968 43.776 48.2325C43.3652 48.0681 43.2009 47.9038 43.0366 47.5752C42.8723 47.3288 42.7901 47.0001 42.7901 46.5894C42.6258 45.1927 42.6258 43.221 42.6258 40.5921V33.0339L56.592 50.6149L56.6742 50.6971H56.7564H58.3173H58.5638V50.4506V35.8271C58.5638 32.7053 58.6459 30.98 58.7281 29.8299C58.8102 29.2548 58.9745 28.7619 59.4674 28.5154C59.7961 28.3511 60.289 28.2689 60.6998 28.1868C60.8641 28.1868 61.1105 28.1868 61.1927 28.1868C61.2749 28.1868 61.2748 28.1868 61.357 28.1868H61.6035V27.9403V26.2972V26.0508H61.357H52.5665H52.32V26.2972V27.9403Z" fill="#1E262F" />
                        <path d="M63.9863 32.6241H63.7399V32.8706H63.9863L65.6294 32.9527H65.7937V32.7884V32.7063C65.7937 32.7063 65.7937 32.6241 65.8759 32.542C65.8759 32.3777 65.9581 32.2133 66.0402 31.8847C66.2045 31.3918 66.451 30.7346 66.6974 30.0773C67.0261 29.3379 67.2725 28.9272 67.8476 28.7629C68.1762 28.6807 68.5048 28.5986 69.0799 28.5164C69.655 28.4342 70.3944 28.4342 71.2981 28.4342H76.8024L62.0146 49.2193V49.3836L62.0968 49.4658L62.5897 50.3695L62.6719 50.4516H62.8362H81.5674H81.7317L81.8138 50.2873C82.0603 49.2193 82.4711 47.5762 82.7175 46.1796C82.8818 45.4402 82.964 44.8651 83.0462 44.3722C83.1283 44.1257 83.1283 43.9614 83.1283 43.7971V43.6328V43.4685L82.964 43.3863L81.4031 43.0577H81.2388L81.0745 43.222V43.3042C81.0745 43.3863 80.9923 43.4685 80.9923 43.6328C80.9101 43.8793 80.7458 44.29 80.5815 44.6186C80.2529 45.4402 79.76 46.3439 79.1849 47.0011L79.1027 47.0833C78.692 47.4941 77.9526 47.7405 76.8024 47.9048C75.5701 48.0691 74.0092 48.1513 72.0375 48.1513C70.3122 48.1513 69.0799 48.1513 68.0119 48.0691L82.6354 27.3662L82.7175 27.2841L82.6354 27.2019L82.2246 26.3804L82.1425 26.2982H81.896H67.4368C66.7796 26.2982 66.5331 26.2161 66.3688 26.1339C66.2867 26.0518 66.2045 26.0518 66.2045 25.8875C66.1224 25.8053 66.1224 25.7231 66.0402 25.641C66.0402 25.5588 65.9581 25.5588 65.9581 25.4767L65.8759 25.3945H65.7116H64.3971H64.1507V25.641C64.0685 27.2841 63.9863 29.0093 63.9042 30.4881C63.822 31.2275 63.822 31.8026 63.7399 32.2133C63.7399 32.4598 63.7399 32.6241 63.7399 32.7063V32.8706L63.9863 32.6241Z" fill="#1E262F" />
                        <path d="M100.052 47.1654L99.8879 47.0011C99.4771 47.4119 98.7377 47.7405 97.5876 47.9048C96.4374 48.0691 94.8765 48.1513 92.9869 48.1513C91.3438 48.1513 90.1115 48.1513 89.2078 48.0691L103.174 27.3662L103.256 27.2841L103.174 27.2019L102.763 26.3804L102.681 26.2982H102.517H88.6328C87.9755 26.2982 87.7291 26.2161 87.6469 26.1339C87.5647 26.0518 87.5648 26.0518 87.4826 25.8875C87.4004 25.8053 87.4004 25.7231 87.3183 25.641C87.3183 25.5588 87.2361 25.5588 87.2361 25.4767L87.154 25.3945H86.9897H85.6752H85.4287V25.641C85.3466 27.2841 85.2644 29.0093 85.1823 30.4881C85.1001 31.2275 85.1001 31.8026 85.018 32.2133C85.018 32.4598 85.018 32.6241 85.018 32.7063V32.8706V33.117H85.2644L86.8254 33.1992H86.9897V33.0349V32.9527C86.9897 32.9527 86.9897 32.8706 87.0718 32.7884C87.0718 32.6241 87.154 32.4598 87.2361 32.1312C87.4004 31.6383 87.6469 30.981 87.8934 30.3238C88.222 29.5844 88.4684 29.1736 89.0435 29.0093C89.29 28.9272 89.7008 28.845 90.2758 28.7629C90.8509 28.6807 91.5082 28.6807 92.4119 28.6807H97.6697L83.5392 49.3014L83.457 49.3836L83.5392 49.4658L83.95 50.3695L84.0321 50.4516H84.1964H102.188H102.353V50.2873C102.599 49.2193 102.928 47.5762 103.256 46.1796C103.421 45.4402 103.503 44.8651 103.585 44.3722C103.667 44.1257 103.667 43.9614 103.667 43.7971V43.6328V43.4685L103.503 43.3863L101.942 43.0577L101.777 42.9756L101.695 43.1399V43.222C101.695 43.3042 101.613 43.3863 101.613 43.5506C101.531 43.7971 101.367 44.2079 101.202 44.5365C100.874 45.358 100.381 46.2617 99.8879 46.919L100.052 47.1654Z" fill="#1E262F" />
                    </g>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1248_10063">
                    <rect width="138.852" height="75.582" fill="white" transform="translate(0.556641 0.0898438)" />
                </clipPath>
                <clipPath id="clip1_1248_10063">
                    <rect width="138.852" height="75.582" fill="white" transform="translate(0.556641 0.0898438)" />
                </clipPath>
                <clipPath id="clip2_1248_10063">
                    <rect width="138.841" height="75.582" fill="white" transform="translate(0.5625 0.0898438)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default NZZ
