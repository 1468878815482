import React from 'react'

const BLICK = ({ width, height }) => {
    return (
        <svg width={width || "140"} height={height || "76"} viewBox="0 0 140 76" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1248_10093)">
                <g clip-path="url(#clip1_1248_10093)">
                    <g clip-path="url(#clip2_1248_10093)">
                        <path d="M2.98587 0.503906H136.076C137.308 0.503906 138.376 1.48976 138.376 2.80423V73.0462C138.376 74.2785 137.39 75.3465 136.076 75.3465H2.98587C1.75355 75.3465 0.685547 74.3607 0.685547 73.0462V2.80423C0.685547 1.48976 1.6714 0.503906 2.98587 0.503906Z" fill="white" />
                        <path d="M2.98587 0.503906H136.076C137.308 0.503906 138.376 1.48976 138.376 2.80423V73.0462C138.376 74.2785 137.39 75.3465 136.076 75.3465H2.98587C1.75355 75.3465 0.685547 74.3607 0.685547 73.0462V2.80423C0.685547 1.48976 1.6714 0.503906 2.98587 0.503906Z" stroke="#E0E0E0" stroke-width="0.821544" />
                        <path d="M114.141 18.332H25.25V57.3553H114.141V18.332Z" fill="#1E262F" />
                        <path d="M79.8008 44.2903V48.8088C78.8971 49.2196 77.6648 49.466 76.4325 49.466C71.914 49.466 68.0527 46.0155 68.0527 41.2506C68.0527 36.4856 71.9961 33.0352 76.4325 33.0352C77.6648 33.0352 78.815 33.2816 79.8008 33.6924V38.1287C78.8971 37.5536 78.0756 37.1429 76.6789 37.1429C74.5429 37.1429 72.5712 38.786 72.5712 41.2506C72.5712 43.7152 74.5429 45.2762 76.6789 45.2762C78.0756 45.2762 78.8971 44.8654 79.8008 44.2903Z" fill="white" />
                        <path d="M86.9484 25.8047V40.0995L92.2885 33.3629H97.875L91.8777 40.6746L98.1214 49.0544H92.5349L87.0306 41.414V49.0544H82.2656V25.8047H86.9484Z" fill="white" />
                        <path d="M58.9337 44.536C57.7835 44.7825 56.4691 44.3717 56.4691 42.2357C56.4691 38.2101 56.4691 25.7227 56.4691 25.7227H51.7041C51.7041 25.7227 51.7041 37.635 51.7041 42.1535C51.7041 46.5899 53.758 49.301 58.9337 48.9723V44.536Z" fill="white" />
                        <path d="M45.7066 37.2228C47.2675 36.4834 48.582 34.9225 48.582 32.7043C48.582 29.0895 45.953 26.707 40.8595 26.707H33.6299V48.9709H42.0096C46.9389 48.9709 49.9786 46.4241 49.9786 42.5628C49.9786 39.9339 48.1712 37.9622 45.7066 37.2228ZM38.5591 30.8147H40.6952C42.6669 30.8147 43.8992 31.7184 43.8992 33.4437C43.8992 35.0868 42.6669 35.9905 40.6952 35.9905H38.5591V30.8147ZM41.681 44.9453H38.5591V39.5231H41.681C43.7349 39.5231 44.9672 40.5911 44.9672 42.2342C45.0493 43.8773 43.7349 44.9453 41.681 44.9453Z" fill="white" />
                        <path d="M66.1634 33.2852H61.3984V49.0588H66.1634V33.2852Z" fill="white" />
                        <path d="M63.7806 31.067C65.3415 31.067 66.656 29.8346 66.656 28.2737C66.656 26.7128 65.3415 25.4805 63.8627 25.4805H63.7806C62.2196 25.4805 60.9873 26.7128 60.9873 28.2737C60.9873 29.8346 62.2196 31.067 63.7806 31.067Z" fill="white" />
                        <path d="M106.089 21.9453H105.021V53.739H106.089V21.9453Z" fill="white" />
                    </g>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1248_10093">
                    <rect width="138.852" height="75.582" fill="white" transform="translate(0.269531 0.0898438)" />
                </clipPath>
                <clipPath id="clip1_1248_10093">
                    <rect width="138.852" height="75.582" fill="white" transform="translate(0.269531 0.0898438)" />
                </clipPath>
                <clipPath id="clip2_1248_10093">
                    <rect width="138.841" height="75.582" fill="white" transform="translate(0.275391 0.0898438)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default BLICK
