import React from 'react'

const Arrow = () => {
    return (
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.3223 3.11328L14.6651 7.45615L10.3223 11.799" stroke="#1D2123" stroke-width="1.30286" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M1.63574 7.45703H14.6643" stroke="#1D2123" stroke-width="1.30286" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default Arrow
