import React from 'react'
import Header from '../components/Header'
import HeroSection from '../Layouts/HeroSection'
import ContactShowup from '../Layouts/ContactShowup'
import OffersSection from '../Layouts/OffersSection'
import AskSection from '../Layouts/AskSection'
import HomesSection from '../Layouts/HomesSection'
import ContactusSection from '../Layouts/ContactusSection'
import KnownSection from '../Layouts/KnownSection'
import StatsSection from '../Layouts/StatsSection'
import BrowseSection from '../Layouts/BrowseSection'
import Footer from '../components/Footer'

const Home = () => {
    return (
        <div>
            <Header />
            <HeroSection />
            <ContactShowup />
            <OffersSection />
            <AskSection />
            <HomesSection />
            <ContactusSection />
            <KnownSection />
            <StatsSection />
            <div className='hidden lg:block'>
                <BrowseSection />
            </div>

            <Footer />
        </div>

    )
}

export default Home
