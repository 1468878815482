import React, { useState } from 'react'
import SectionBreaker from '../components/SectionBreaker'
import SecondContainer from '../components/SecondContainer'
import { useTranslation } from 'react-i18next';
// import Button from '../components/Button'

const BrowseSection = () => {

    const [selectedTab, setselectedTab] = useState(1);

    const { t } = useTranslation()

    return (
        <SectionBreaker>
            <SecondContainer>
                <div className='flex flex-col gap-[40px]'>
                    <div className='text-[#30313A] text-[38.81px] font-semibold'>
                       {t('browse-title')}
                    </div>
                    <div className='bg-[#F9F9FA] rounded-[5.4px] grid grid-cols-6 gap-[30px] p-[10px]'>
                        <div className={`${selectedTab === 1 ? 'bg-[#FFFFFF] text-[#30313A] shadow-lg' : 'bg-[transparent] text-[#838389] shadow-none'}  cursor-pointer font-medium text-[14.17px]  py-[5px] px-[7px] text-center rounded-[3.6px]`}
                            onClick={() => setselectedTab(1)}>
                            {t("browsebystate")}
                        </div>
                        <div className={`${selectedTab === 2 ? 'bg-[#FFFFFF] text-[#30313A] shadow-lg' : 'bg-[transparent] text-[#838389] shadow-none'} cursor-pointer  font-medium text-[14.17px]  py-[5px] px-[7px] text-center rounded-[3.6px]`}
                            onClick={() => setselectedTab(2)}>
                            {t("browsebystate")}
                        </div>
                        <div className={`${selectedTab === 3 ? 'bg-[#FFFFFF] text-[#30313A] shadow-lg' : 'bg-[transparent] text-[#838389] shadow-none'} cursor-pointer  font-medium text-[14.17px]  py-[5px] px-[7px] text-center rounded-[3.6px]`}
                            onClick={() => setselectedTab(3)}>
                            {t("browsebystate")}
                        </div>
                        <div className={`${selectedTab === 4 ? 'bg-[#FFFFFF] text-[#30313A] shadow-lg' : 'bg-[transparent] text-[#838389] shadow-none'} cursor-pointer  font-medium text-[14.17px]  py-[5px] px-[7px] text-center rounded-[3.6px]`}
                            onClick={() => setselectedTab(4)}>
                            {t("browsebystate")}
                        </div>
                        <div className={`${selectedTab === 5 ? 'bg-[#FFFFFF] text-[#30313A] shadow-lg' : 'bg-[transparent] text-[#838389] shadow-none'} cursor-pointer  font-medium text-[14.17px]  py-[5px] px-[7px] text-center rounded-[3.6px]`}
                            onClick={() => setselectedTab(5)}>
                            {t("browsebystate")}
                        </div>
                        <div className={`${selectedTab === 6 ? 'bg-[#FFFFFF] text-[#30313A] shadow-lg' : 'bg-[transparent] text-[#838389] shadow-none'} cursor-pointer  font-medium text-[14.17px]  py-[5px] px-[7px] text-center rounded-[3.6px]`}
                            onClick={() => setselectedTab(6)}>
                            {t("browsebystate")}
                        </div>
                    </div>
                    {selectedTab === 1 && (
                        <div className='grid grid-cols-4 gap-x-[40px] gap-y-[20px]  font-medium text-[#30313A] text-[14.06px] pl-[30px]'>
                            <div>
                                Arizona
                            </div>
                            <div>
                                California
                            </div>
                            <div>
                                Colorado
                            </div>
                            <div>
                                Delaware
                            </div>
                            <div>
                                District of Columbia
                            </div>
                            <div>
                                Florida
                            </div>
                            <div>
                                Georgia
                            </div>
                            <div>
                                Idaho
                            </div>
                            <div>
                                Iowa
                            </div>
                            <div>
                                Maryland
                            </div>
                            <div>
                                Massachusetts
                            </div>
                            <div>
                                Minnesota
                            </div>
                            <div>
                                Missouri
                            </div>
                            <div>
                                Nevada
                            </div>
                            <div>
                                New Mexico
                            </div>
                            <div>
                                New York
                            </div>
                            <div>
                                North Carolina
                            </div>
                            <div>
                                Ohio
                            </div>
                            <div>
                                Oklahoma
                            </div>
                            <div>
                                Oregon
                            </div>
                            <div>
                                Pennsylvania
                            </div>
                            <div>
                                South Carolina
                            </div>
                            <div>
                                Tennessee
                            </div>
                            <div>
                                Texas
                            </div>
                            <div>
                                Utah
                            </div>
                            <div>
                                Virginia
                            </div>
                            <div>
                                Washington
                            </div>
                            <div>
                                West Virginia
                            </div>
                            <div>
                                Wyoming
                            </div>
                        </div>
                    )}
                    {/* <Button button={'Browse more'} /> */}
                </div>
            </SecondContainer>
        </SectionBreaker>
    )
}

export default BrowseSection
