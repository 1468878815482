import React from 'react'

const Tree = () => {
    return (
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1248_9649)">
                <path d="M18.9942 17.0028L15.4054 12.9378H16.7813C17.1861 12.9378 17.5441 12.7132 17.7148 12.3512C17.8855 11.99 17.8294 11.5758 17.5675 11.2708L14.0577 7.18784H15.3555C15.7643 7.18784 16.1327 6.9475 16.2926 6.57645C16.4507 6.21124 16.3761 5.78627 16.1021 5.49473L11.1486 0.21731C10.8777 -0.0724365 10.3723 -0.0724365 10.101 0.21731L5.14703 5.49473C4.873 5.78627 4.79888 6.21124 4.95656 6.57645C5.11648 6.9475 5.48529 7.18784 5.89408 7.18784H7.19187L3.68168 11.2717C3.42068 11.5763 3.36453 11.99 3.53478 12.3516C3.70548 12.7132 4.06306 12.9378 4.46826 12.9378H5.84421L2.25541 17.0028C1.98587 17.3078 1.92478 17.7256 2.09548 18.0926C2.26394 18.4542 2.63455 18.6878 3.03929 18.6878H9.1873V19.7862L7.82662 21.9604C7.58763 22.4384 7.93533 23.0008 8.46945 23.0008H12.7802C13.3143 23.0008 13.662 22.4384 13.423 21.9604L12.0623 19.7862V18.6878H18.2103C18.6151 18.6878 18.9857 18.4542 19.1541 18.0926C19.3248 17.7256 19.2637 17.3078 18.9942 17.0028Z" fill="#30313A" />
            </g>
            <defs>
                <clipPath id="clip0_1248_9649">
                    <rect width="23" height="23" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default Tree
