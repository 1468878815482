import React from 'react'

const Agents = () => {
    return (
        <svg width="120" height="121" viewBox="0 0 120 121" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 110.309V85.3086C100 75.8805 100 71.1665 97.0711 68.2375C94.1421 65.3086 89.4281 65.3086 80 65.3086L60 110.309L40 65.3086C30.5719 65.3086 25.8579 65.3086 22.9289 68.2375C20 71.1665 20 75.8805 20 85.3086V110.309" stroke="#141B34" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M60 75.3086L57.5 95.3086L60 102.809L62.5 95.3086L60 75.3086ZM60 75.3086L55 65.3086H65L60 75.3086Z" stroke="#141B34" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M77.5 32.8086V27.8086C77.5 18.1436 69.665 10.3086 60 10.3086C50.335 10.3086 42.5 18.1436 42.5 27.8086V32.8086C42.5 42.4736 50.335 50.3086 60 50.3086C69.665 50.3086 77.5 42.4736 77.5 32.8086Z" stroke="#141B34" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default Agents
