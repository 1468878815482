import React from 'react'

const Instagram = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1.25176" y="0.60332" width="26.1" height="26.1" rx="13.05" stroke="#EAEAEB" stroke-width="0.9" />
            <g clip-path="url(#clip0_1248_10297)">
                <path d="M13.9279 11.1281C12.5863 11.1281 11.5042 12.2102 11.5042 13.5518C11.5042 14.8933 12.5863 15.9754 13.9279 15.9754C15.2695 15.9754 16.3516 14.8933 16.3516 13.5518C16.3516 12.2102 15.2695 11.1281 13.9279 11.1281ZM13.9279 15.1275C13.061 15.1275 12.3522 14.4208 12.3522 13.5518C12.3522 12.6827 13.0588 11.9761 13.9279 11.9761C14.797 11.9761 15.5036 12.6827 15.5036 13.5518C15.5036 14.4208 14.7949 15.1275 13.9279 15.1275ZM17.016 11.029C17.016 11.3433 16.7629 11.5943 16.4507 11.5943C16.1364 11.5943 15.8854 11.3412 15.8854 11.029C15.8854 10.7168 16.1385 10.4637 16.4507 10.4637C16.7629 10.4637 17.016 10.7168 17.016 11.029ZM18.6213 11.6027C18.5854 10.8454 18.4124 10.1747 17.8577 9.62201C17.305 9.06936 16.6342 8.89639 15.877 8.85842C15.0965 8.81412 12.7572 8.81412 11.9767 8.85842C11.2216 8.89428 10.5508 9.06725 9.99604 9.6199C9.44127 10.1726 9.27041 10.8433 9.23244 11.6006C9.18814 12.3811 9.18814 14.7204 9.23244 15.5008C9.2683 16.2581 9.44127 16.9289 9.99604 17.4815C10.5508 18.0342 11.2195 18.2072 11.9767 18.2451C12.7572 18.2894 15.0965 18.2894 15.877 18.2451C16.6342 18.2093 17.305 18.0363 17.8577 17.4815C18.4103 16.9289 18.5833 16.2581 18.6213 15.5008C18.6656 14.7204 18.6656 12.3832 18.6213 11.6027ZM17.613 16.3383C17.4485 16.7517 17.1299 17.0702 16.7144 17.2369C16.0921 17.4837 14.6156 17.4267 13.9279 17.4267C13.2403 17.4267 11.7616 17.4815 11.1414 17.2369C10.728 17.0723 10.4095 16.7538 10.2428 16.3383C9.99604 15.716 10.053 14.2394 10.053 13.5518C10.053 12.8641 9.99814 11.3854 10.2428 10.7653C10.4074 10.3519 10.7259 10.0333 11.1414 9.8667C11.7637 9.6199 13.2403 9.67686 13.9279 9.67686C14.6156 9.67686 16.0942 9.62201 16.7144 9.8667C17.1278 10.0312 17.4463 10.3497 17.613 10.7653C17.8598 11.3876 17.8028 12.8641 17.8028 13.5518C17.8028 14.2394 17.8598 15.7181 17.613 16.3383Z" fill="#EAEAEB" />
            </g>
            <defs>
                <clipPath id="clip0_1248_10297">
                    <rect width="10.8" height="10.8" fill="white" transform="translate(9.2002 8.15332)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default Instagram
