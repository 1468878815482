import React from 'react'
import SecondContainer from './SecondContainer'
import FooterTexts from './FooterTexts'
import Twitter from '../assets/Twitter'
import Facebook from '../assets/Facebook'
import Instagram from '../assets/Instagram'
import Linkedin from '../assets/Linkedin'
import { useTranslation } from 'react-i18next'

const Footer = () => {

    const { t } = useTranslation()

    return (
        <div className='pt-[80px] lg:pt-[30px]'>
            <div className='bg-[#1E262F] pb-[30px]'>
                <SecondContainer>
                    <div className='grid lg:grid-cols-[2fr_1.1fr]'>
                        <div className='grid grid-cols-2 lg:grid-cols-4 gap-[30px] pt-[50px] pb-[25px] lg:py-[50px]  '>
                            <div className='flex flex-col gap-y-[10px]'>
                                <div className='text-white font-semibold text-[16.7px] lg:text-[16.2px] '>
                                    {t("services")}
                                </div>
                                <FooterTexts>{t('buy')}</FooterTexts>
                                <FooterTexts>{t('sell')}</FooterTexts>
                                <FooterTexts>{t('companycashoffer')}</FooterTexts>
                                <FooterTexts>{t('buybeforeyousell')}</FooterTexts>
                            </div>
                            <div className='flex flex-col gap-y-[10px]'>
                                <div className='text-white font-semibold text-[16.7px] lg:text-[16.2px]  '>
                                    {t("Resources")}
                                </div>
                                <FooterTexts>{t("Blog")}</FooterTexts>
                                <FooterTexts>{t("NeighborhoodGuide")}</FooterTexts>
                                <FooterTexts>{t("CostofLiving")}</FooterTexts>
                                <FooterTexts>{t("MarketAnalysis")}</FooterTexts>
                            </div>
                            <div className='flex flex-col gap-y-[10px]'>
                                <div className='text-white font-semibold text-[16.7px] lg:text-[16.2px]  '>
                                    {t("AboutLorem")}
                                </div>
                                <FooterTexts>{t("AboutUs")}</FooterTexts>
                                <FooterTexts>{t("Contactus")}</FooterTexts>
                                <FooterTexts>{t("Investors")}</FooterTexts>
                                <FooterTexts>{t("Agents")}</FooterTexts>
                            </div>
                            <div className='hidden lg:flex flex-col gap-y-[10px]'>
                                <div className='text-white font-semibold text-[16.2px] '>
                                    Partners
                                </div>
                                <FooterTexts>Partner</FooterTexts>
                            </div>

                        </div>
                        <div className='flex flex-col gap-y-[10px] pb-[25px] lg:hidden'>
                            <div className='text-white font-semibold text-[16.7px] lg:text-[16.2px]  '>
                                Partners
                            </div>
                            <FooterTexts>Partner</FooterTexts>
                        </div>
                        <div className='flex flex-col justify-center text-white font-normal text-[14px] gap-y-[20px]'>
                            <div className='hidden lg:flex'>
                                {t("footer-desc")}
                            </div>
                            <div className='flex flex-row gap-[15px]'>
                                <Twitter />
                                <Facebook />
                                <Instagram />
                                <Linkedin />
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-center text-[#FFFFFF]  text-[12px] font-light	pt-[50px] lg:pt-[0px]'>
                        Immoverband schweiz © 2024
                    </div>

                </SecondContainer>
            </div>
        </div>
    )
}

export default Footer
