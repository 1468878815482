import React from 'react'

const Stats = ({ number, text }) => {
    return (
        <div className='flex flex-col'>
            <div className='text-[#1D2123] text-[26px] lg:text-[34px] font-medium'>
                {number}
            </div>
            <div className='text-[#1D2123] text-[17.58px] font-normal'>
                {text}
            </div>
        </div>
    )
}

export default Stats
