import React from 'react'

const Payment = () => {
    return (
        <svg width="120" height="121" viewBox="0 0 120 121" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M60 90.3086C53.3373 93.4204 44.5854 95.3086 35 95.3086C29.6704 95.3086 24.5984 94.7249 20 93.6716C17.044 92.9946 15.566 92.656 13.7949 91.2547C12.7848 90.4554 11.5241 88.8733 10.9706 87.7103C10 85.6711 10 83.6936 10 79.7387V25.8784C10 20.9543 15.2002 17.5722 20 18.6716C24.5984 19.7249 29.6704 20.3086 35 20.3086C44.5854 20.3086 53.3373 18.4204 60 15.3086C66.6627 12.1968 75.4146 10.3086 85 10.3086C90.3296 10.3086 95.4016 10.8923 100 11.9456C102.956 12.6226 104.434 12.9612 106.205 14.3625C107.215 15.1618 108.476 16.7439 109.029 17.9069C110 19.9461 110 21.9236 110 25.8784V79.7387C110 84.6629 104.8 88.045 100 86.9456C95.4016 85.8923 90.3296 85.3086 85 85.3086C75.4146 85.3086 66.6627 87.1968 60 90.3086Z" stroke="#141B34" stroke-width="4" />
            <path d="M10 105.309C16.6627 108.42 25.4146 110.309 35 110.309C44.5854 110.309 53.3373 108.42 60 105.309C66.6627 102.197 75.4146 100.309 85 100.309C94.5854 100.309 103.337 102.197 110 105.309" stroke="#141B34" stroke-width="4" stroke-linecap="round" />
            <path d="M72.5 52.8086C72.5 59.7122 66.9036 65.3086 60 65.3086C53.0964 65.3086 47.5 59.7122 47.5 52.8086C47.5 45.905 53.0964 40.3086 60 40.3086C66.9036 40.3086 72.5 45.905 72.5 52.8086Z" stroke="#141B34" stroke-width="4" />
            <path d="M27.5 57.8086L27.5 57.8535" stroke="#141B34" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M92.5 47.7695L92.5 47.8144" stroke="#141B34" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default Payment
