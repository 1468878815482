import React from 'react'
import SectionBreaker from '../components/SectionBreaker'
import SecondContainer from '../components/SecondContainer'
import Stats from '../components/Stats'
import ShowmoreCards from '../components/ShowmoreCards'

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

const StatsSection = () => {
    return (
        <SectionBreaker>
            <SecondContainer>
                
                <div className='grid lg:grid-cols-2 gap-[50px]'>
                    <div className='flex flex-col gap-[30px]'>
                        <div className='font-medium text-[24px] lg:text-[23.92px] text-[#1D2123]'>
                            Der führende Real Estate Tech
                            Agent der Schweiz
                        </div>
                        <div className='grid grid-cols-2 gap-[20px]'>
                            <Stats number={"7'195+"} text={'Transaktionen'} />
                            <Stats number={"4"} text={'Portale'} />
                            <Stats number={"195+"} text={'Expertinnen'} />
                            <Stats number={"180+"} text={'Partner'} />
                        </div>
                    </div>
                    <div className='hidden lg:grid grid-cols-2 gap-[20px]'>
                        <ShowmoreCards unserestory={true} text={'Unsere Story'} />
                        <ShowmoreCards text={'PROPCHAIN Immobiliensoftware'} />
                    </div>
                    <div className='w-full max-w-[400px] lg:hidden'>
                        <Swiper className="mySwiper"
                            slidesPerView={1.3}
                            spaceBetween={10} >
                            <SwiperSlide>
                                <ShowmoreCards unserestory={true} text={'Unsere Story'} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ShowmoreCards text={'PROPCHAIN Immobiliensoftware'} />
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </SecondContainer>
        </SectionBreaker>
    )
}

export default StatsSection
