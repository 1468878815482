import React from 'react'

const Twitter = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.650195" y="0.60332" width="26.1" height="26.1" rx="13.05" stroke="#EAEAEB" stroke-width="0.9" />
            <g clip-path="url(#clip0_1248_10291)">
                <path d="M17.89 12.3511C17.8969 12.447 17.8969 12.543 17.8969 12.6389C17.8969 15.565 15.6697 18.9366 11.5992 18.9366C10.3451 18.9366 9.18015 18.5734 8.2002 17.943C8.37837 17.9635 8.54968 17.9704 8.73471 17.9704C9.76946 17.9704 10.722 17.6209 11.4827 17.0247C10.5096 17.0041 9.6941 16.3668 9.41313 15.4896C9.5502 15.5102 9.68724 15.5239 9.83116 15.5239C10.0299 15.5239 10.2286 15.4965 10.4136 15.4485C9.39944 15.2429 8.63876 14.3521 8.63876 13.2762V13.2488C8.93341 13.4133 9.27608 13.5161 9.63925 13.5297C9.04306 13.1323 8.65247 12.4539 8.65247 11.6863C8.65247 11.2752 8.76209 10.8983 8.95398 10.5693C10.0436 11.9125 11.6814 12.7896 13.5179 12.8856C13.4837 12.7211 13.4631 12.5498 13.4631 12.3785C13.4631 11.1587 14.4499 10.165 15.6766 10.165C16.3139 10.165 16.8895 10.4323 17.2938 10.864C17.7941 10.7681 18.2737 10.5831 18.6986 10.3295C18.5342 10.8435 18.1847 11.2752 17.7255 11.5493C18.171 11.5013 18.6027 11.378 19.0002 11.2067C18.6987 11.6452 18.3218 12.0358 17.89 12.3511Z" fill="#EAEAEB" />
            </g>
            <defs>
                <clipPath id="clip0_1248_10291">
                    <rect width="10.8" height="10.8" fill="white" transform="translate(8.2002 9.15332)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default Twitter
