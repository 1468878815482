import React from 'react';
import { useTranslation } from 'react-i18next';
import SectionBreaker from '../components/SectionBreaker';
import SecondContainer from '../components/SecondContainer';

const ContactusSection = () => {
    const { t } = useTranslation();

    return (
        <SectionBreaker>
            <SecondContainer>
                <div className='bg-[#1E262F] text-white rounded-[14.4px] flex flex-col justify-center items-center px-[30px] py-[50px] gap-[25px]'>
                    <div className='font-normal text-[24px] lg:text-[29.62px] text-center'>
                        {t('contactus')}
                    </div>
                    <div className='font-normal text-[14px] lg:text-[14.29px] text-center w-full max-w-[280px] lg:max-w-[700px]'>
                        {t('description-contact')}
                    </div>
                    <button className='border border-[0.9px] border-[#EAEAEB] text-white p-[15px] rounded-[5.4px] text-[14.29px] w-full lg:max-w-[200px]'>
                        {t('contactus-button')}
                    </button>
                </div>
            </SecondContainer>
        </SectionBreaker>
    );
};

export default ContactusSection;
