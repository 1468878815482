// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {
        translation: {
            "hero": "The world’s first AI-powered home search",
            "contactus": "Get in touch with us",
            "contactus-button": "Contact us",
            "description-contact": "For any inquiries or support, don't hesitate to contact us. Our dedicated team is ready to assist you with any information or help you may require. We value your communication and are committed to providing excellent service.",
            "quality": "Quality properties",
            "description-quality": "including durability, reliability, efficiency, and performance.",
            "payment": "Payment methods",
            "description-payment": "Lorem ipsum dolor lampur kosmi sit amet",
            "innovative": "Innovative designs",
            "description-innovative": "Creative and cutting-edge solutions.",
            "agents": "Qualified agents",
            "description-agents": "Expert and professional.",
            "ask-title": "Don’t buy a home without us.",
            "ask-desc": "Find your exact match with powerful market insights.",
            "ask-home": "Ask about a home",
            "look-home": "Look for a home",
            "question1": "Is there high seismic risk?",
            "question2": "Is this home near a high voltage power line?",
            "question3": "What repairs have been done? Permit history?",
            "question4": "Is there high seismic risk?",
            "question5": "Show me family friendly neighborhoods",
            "question6": "How’s the market right now?",
            "question7": "Homes with big backyards built after 1980",
            "question8": "Tell me more about this neighborhood",
            "homes-title": "Homes in high demand",
            "nature-title": "Nature nearby",
            "nature-desc": "Homes within 2 miles of a city or state park.",
            "ev-title": "EV-charging friendly",
            "ev-natur": "Homes with easy access to EV charging.",
            "browse-title":"Your new home is waiting, browse by:",
            "browsebystate": "Browse by state",
            "services": "Services",
            "buy": "Buy",
            "companycashoffer": "Company Cash Offer",
            "buybeforeyousell": "Buy before you sell",
            "Resources": "Resources",
            "Blog": "Blog",
            "NeighborhoodGuide": "Neighborhood Guide",
            "CostofLiving": "Cost of Living",
            "MarketAnalysis": "Market Analysis",
            "AboutLorem": "About Lorem",
            "AboutUs": "About Us",
            "Contactus": "Contact us",
            "Investors": "Investors",
            "Agents": "Agents",
            "footer-desc": "By searching you agree to the Terms of Use Privacy Policy and Lorem Privacy Policy"
        }
    },
    de: {
        translation: {
            "hero": "Die weltweit erste KI-gestützte Haussuche",
            "contactus": "Kontaktieren Sie uns",
            "contactus-button": "Kontaktiere uns",
            "description-contact": "Bei Fragen oder Unterstützung zögern Sie nicht, uns zu kontaktieren. Unser engagiertes Team steht bereit, Ihnen mit allen Informationen oder Hilfestellungen zur Seite zu stehen. Wir schätzen Ihre Kommunikation und sind bestrebt, exzellenten Service zu bieten.",
            "quality": "Qualitätsmerkmale",
            "description-quality": "Einschließlich Haltbarkeit, Zuverlässigkeit, Effizienz und Leistung",
            "payment": "Payment methods",
            "description-payment": "Lorem ipsum dolor lampur kosmi sit amet",
            "innovative": "Innovatives Design",
            "description-innovative": "Kreative und wegweisende Lösungen.",
            "agents": "Qualifizierte Mitarbeiter",
            "description-agents": "Fachkundig und professionell.",
            "ask-title": "Kaufen Sie kein Haus ohne uns.",
            "ask-desc": "Finden Sie Ihre genaue Übereinstimmung mit leistungsstarken Marktanalysen.",
            "ask-home": "Fragen Sie nach einem Haus",
            "look-home": "Suche nach einem Haus",
            "question1": "Gibt es ein hohes Erdbebenrisiko?",
            "question2": "Befindet sich dieses Haus in der Nähe einer Hochspannungsleitung?",
            "question3": "Welche Reparaturen wurden durchgeführt? Genehmigungshistorie?",
            "question4": "Gibt es ein hohes Erdbebenrisiko?",
            "question5": "Zeige mir familienfreundliche Nachbarschaften",
            "question6": "Wie ist der Markt derzeit?",
            "question7": "Häuser mit großen Gärten, die nach 1980 gebaut wurden",
            "question8": "Erzähl mir mehr über diese Nachbarschaft",
            "homes-title": "Häuser mit hoher Nachfrage",
            "nature-title": "Natur in der Nähe",
            "nature-desc": "Häuser innerhalb von 2 Meilen von einem Stadt- oder Staatspark.",
            "ev-title": "E-Ladestationen freundlich",
            "ev-natur": "Häuser mit einfachem Zugang zu E-Ladestationen.",
            "browse-title":"Ihr neues Zuhause wartet auf Sie, durchsuchen Sie nach:",
            "browsebystate": "Durchsuchen Sie nach Bundesstaat",
            "services": "Dienstleistungen",
            "buy": "Kaufen",
            "companycashoffer": "Verkaufen Firmen-Cash-Angebot Kaufen",
            "buybeforeyousell": "Bevor Sie verkaufen",
            "Resources": "Ressourcen",
            "Blog": "Blog",
            "NeighborhoodGuide": "Nachbarschaftsführer",
            "CostofLiving": "Lebenshaltungskosten",
            "MarketAnalysis": "Marktanalyse",
            "AboutLorem": "Über Lorem",
            "AboutUs": " Über uns",
            "Contactus": "Kontaktieren Sie uns",
            "Investors": "Investoren",
            "Agents": "Makler",
            "footer-desc": "Durch die Suche stimmen Sie den Nutzungsbedingungen, der Datenschutzrichtlinie und der Lorem-Datenschutzrichtlinie zu."
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en", // default language
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
