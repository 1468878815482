import React from 'react'

const Charger = () => {
    return (
        <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.6667 20.9208H5.55556C5.24861 20.9208 5 21.1694 5 21.4763V22.5875C5 22.8944 5.24861 23.143 5.55556 23.143H16.6667C16.9736 23.143 17.2222 22.8944 17.2222 22.5875V21.4763C17.2222 21.1694 16.9736 20.9208 16.6667 20.9208ZM23.8889 9.80968V8.14301C23.8889 7.83607 23.6403 7.58746 23.3333 7.58746C23.0264 7.58746 22.7778 7.83607 22.7778 8.14301V9.80968H21.6667V8.14301C21.6667 7.83607 21.4181 7.58746 21.1111 7.58746C20.8042 7.58746 20.5556 7.83607 20.5556 8.14301V9.80968H20C19.6931 9.80968 19.4444 10.0583 19.4444 10.3652V11.4763C19.4444 12.718 20.2646 13.7572 21.3889 14.1128V18.227C21.3889 18.7114 21.059 19.1618 20.5813 19.2406C19.9729 19.3409 19.4444 18.8718 19.4444 18.2819V17.3097C19.4444 15.6222 18.0764 14.2541 16.3889 14.2541H16.1111V7.58746C16.1111 6.36003 15.1163 5.36523 13.8889 5.36523H8.33333C7.1059 5.36523 6.11111 6.36003 6.11111 7.58746V19.8097H16.1111V15.9208H16.3889C17.1559 15.9208 17.7778 16.5427 17.7778 17.3097V18.1642C17.7778 19.5416 18.7819 20.7739 20.1531 20.9076C21.726 21.0614 23.0556 19.8239 23.0556 18.2819V14.1128C24.1799 13.7572 25 12.718 25 11.4763V10.3652C25 10.0583 24.7514 9.80968 24.4444 9.80968H23.8889ZM14.0309 11.468L10.7774 16.2944C10.701 16.41 10.5618 16.4763 10.4167 16.4763C10.1503 16.4763 9.94896 16.2583 10.0115 16.0277L10.8108 13.143H8.75C8.49826 13.143 8.30382 12.9489 8.33715 12.7302L8.89271 9.01489C8.92014 8.83399 9.09653 8.69857 9.30556 8.69857H11.6667C11.9403 8.69857 12.1396 8.92565 12.0694 9.15725L11.6667 10.9208H13.6701C13.991 10.9208 14.1913 11.2257 14.0309 11.468Z" fill="#30313A" />
        </svg>
    )
}

export default Charger
