import React from 'react'

const Innovativedesigns = () => {
    return (
        <svg width="120" height="121" viewBox="0 0 120 121" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.7143 70.3086C22.0867 64.3494 20 57.3674 20 49.9029C20 28.0356 37.9086 10.3086 60 10.3086C82.0914 10.3086 100 28.0356 100 49.9029C100 57.3674 97.9133 64.3494 94.2857 70.3086" stroke="#141B34" stroke-width="4" stroke-linecap="round" />
            <path d="M36.9144 85.7998C36.455 84.4167 36.2253 83.7252 36.2521 83.1649C36.3087 81.98 37.0557 80.9398 38.1579 80.5112C38.679 80.3086 39.4053 80.3086 40.8579 80.3086H79.1422C80.5948 80.3086 81.321 80.3086 81.8421 80.5112C82.9443 80.9398 83.6913 81.98 83.7479 83.1649C83.7747 83.7252 83.545 84.4167 83.0856 85.7998C82.2367 88.3558 81.8122 89.6339 81.1575 90.6688C79.7859 92.8368 77.6359 94.3921 75.1528 95.0125C73.9675 95.3086 72.6252 95.3086 69.9407 95.3086H50.0593C47.3748 95.3086 46.0325 95.3086 44.8472 95.0125C42.3641 94.3921 40.2141 92.8368 38.8425 90.6688C38.1878 89.6339 37.7633 88.3558 36.9144 85.7998Z" stroke="#141B34" stroke-width="4" />
            <path d="M75 95.3086L74.3534 98.5416C73.6463 102.077 73.2928 103.845 72.5006 105.241C71.2758 107.401 69.2912 109.028 66.9331 109.806C65.4079 110.309 63.6053 110.309 60 110.309C56.3947 110.309 54.5921 110.309 53.0669 109.806C50.7088 109.028 48.7242 107.401 47.4994 105.241C46.7072 103.845 46.3537 102.077 45.6466 98.5416L45 95.3086" stroke="#141B34" stroke-width="4" />
            <path d="M60 80.3086V55.3086" stroke="#141B34" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default Innovativedesigns
