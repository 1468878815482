import React from 'react'
import SecondContainer from '../components/SecondContainer'
import Phone from '../assets/Phone'
import Woman from '../assets/Foto.png'

const ContactShowup = () => {
    return (

        <div className='bg-[#1D2123]'>
            <SecondContainer>
                <div className=' flex flex-row justify-between items-center py-[17px] lg:py-[12px] gap-[10px] '>
                    <div className='flex flex-row gap-[10px] items-center'>
                        <div className='rounded-full bg-white h-[42px] w-[42px]'>
                            <img src={Woman} alt='Woman' />
                        </div>
                        <div className='text-white text-[14px] font-normal lg:hidden'>
                            Jederzeit für Sie erreichbar.
                        </div>
                        <div className='hidden text-[15.86px] leading-[20px]  lg:flex xl:text-[16px]  font-normal w-full max-w-[345px] text-white font-inter'>
                            Sie haben eine Frage? Unsere
                            Expert: innen freuen sich auf Ihren Anruf.
                        </div>
                        <div className='hidden text-[15.86px] lg:flex xl:text-[17.86px] font-normal text-white font-inter'>
                            +41 22 111 22 00
                        </div>
                    </div>
                    <div>
                        <button className='hidden lg:flex text-white bg-[#4A8DAE] py-[10px] px-[25px] font-inter rounded-[3.6px]'>Jetzt kontaktieren</button>
                        <button className='flex text-white bg-[#4A8DAE] py-[10px] px-[15px] font-inter rounded-[3.6px] lg:hidden'><Phone /></button>
                    </div>
                </div>
            </SecondContainer>

        </div>
    )
}

export default ContactShowup
